import { FC, useState, useMemo, useEffect } from 'react';

// router
import { useHistory } from 'react-router-dom';

// redux
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../store';
import { updateStep, updateFinish, updateCountdown, updateCurrentPlay } from '../store/quiz';

// assets
import style from '../assets/style/components/gameStep.module.scss';
import start from '../assets/image/play/txt_start.png';
import finish from '../assets/image/play/txt_finish.png';
import timeout from '../assets/image/play/txt_timeout.png';
import corrected from '../assets/image/play/img_corrected.png';
import failed from '../assets/image/play/img_failed.png';

// component
import { Bounce } from './bounce';

//
export const GameStep: FC = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const { result, step, currentPlay, loadedmetadata } = useSelector(
    (state: RootState) => state.quiz
  );

  // 正解メロディの再生ボタン押下イベント
  const actPlay = () => {
    if (step !== 1) {
      dispatch(updateStep(1));
      dispatch(updateCurrentPlay(5));
    }
  };

  // 正解メロディのスキップボタン押下イベント
  const skipPlay = () => {
    dispatch(updateStep(1));
    dispatch(updateCurrentPlay(null));
  };

  // はじめるボタン押下イベント
  const startPlay = () => {
    dispatch(updateStep(3));
  };

  const [className, setClassName] = useState<string[]>([style.gameStart, style.isOpen]);

  const [loadedAudio, setLoadedAudio] = useState<boolean>(false);

  // 再生
  useEffect(() => {
    if (step === 1 && currentPlay === null) dispatch(updateStep(2));
  }, [dispatch, currentPlay, step]);

  // ステップ
  useMemo(() => {
    if (step === 3) {
      setTimeout(() => {
        dispatch(updateStep(4));
      }, 1000);
    }
    if (step === 4) {
      dispatch(updateCountdown(true));
      setClassName([style.gameStart]);
    }
    if (step === 5 || step === 6) {
      dispatch(updateFinish(true));
      dispatch(updateCountdown(false));
      setClassName([style.gameStart, style.isOpen]);
    }
    if (step === 7) {
      setClassName([style.gameStart]);
    }
    if (step === 8) {
      setClassName([style.gameStart, style.isOpen]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [step]);

  useMemo(() => {
    // 全ての音源がロード完了しているかどうか
    const loading = loadedmetadata.find(loaded => loaded === false);
    setLoadedAudio(loading === undefined);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadedmetadata]);

  interface Contents {
    [key: string]: JSX.Element;
  }
  const contents: Contents = {
    music: (
      <div className={style.text}>
        <p>
          正解のメロディを
          <br />
          確認してみよう！
        </p>
        <button
          className={style.play}
          onClick={() => actPlay()}
          disabled={loadedmetadata[loadedmetadata.length - 1] === false}
        >
          <svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 37 37">
            <path
              fill="#FFFCAD"
              d="M26.4,18.3c0.5,0.3,0.6,0.9,0.4,1.4c-0.1,0.2-0.2,0.3-0.4,0.4L14,27.2c-0.5,0.3-1.1,0.1-1.4-0.4
        c-0.1-0.2-0.1-0.3-0.1-0.5V12c0-0.6,0.4-1,1-1c0.2,0,0.3,0,0.5,0.1L26.4,18.3z"
            />
          </svg>
        </button>
        <button className={style.skip} onClick={() => skipPlay()} disabled={!loadedAudio}>
          スキップする
        </button>
      </div>
    ),
    timer: (
      <div className={style.text}>
        <p>
          100秒以内に
          <br />
          正しい順番に並べよう！
        </p>
        <Bounce className={style.button} onClick={() => startPlay()} disabled={!loadedAudio}>
          {loadedAudio ? `はじめる` : `読み込み中...`}
        </Bounce>
      </div>
    ),
    start: (
      <div className={style.text}>
        <img className={style.start} src={start} alt="スタート" />
      </div>
    ),
    finish: (
      <div className={style.text}>
        <img className={style.finish} src={finish} alt="フィニッシュ" />
        <Bounce className={style.finished_button} onClick={() => dispatch(updateStep(7))}>
          答え合わせをする
        </Bounce>
      </div>
    ),
    timeout: (
      <div className={style.text}>
        <img className={style.timeout} src={timeout} alt="タイムアップ" />
        <Bounce className={style.finished_button} onClick={() => dispatch(updateStep(7))}>
          答え合わせをする
        </Bounce>
      </div>
    ),
    empty: <div></div>,
  };

  return (
    <div className={className.join(' ')}>
      <div className={style.screen}>
        {
          contents[
            step < 2
              ? 'music'
              : step === 2
              ? 'timer'
              : step === 3
              ? 'start'
              : step === 5
              ? 'finish'
              : step === 6
              ? 'timeout'
              : step === 7
              ? 'playing'
              : 'empty'
          ]
        }
      </div>
      {/* ローディング対策 ↓ 先にリザルト画像を透過表示 */}
      <div
        className={style.judging}
        data-judging={step === 8 ? (result.isCorrect ? 'corrected' : 'failed') : undefined}
        onClick={() => history.push(`/result/${result.quizId}`)}
      >
        <img className={style.corrected} src={corrected} alt="正解" />
        <img className={style.failed} src={failed} alt="不正解" />
      </div>
    </div>
  );
};
