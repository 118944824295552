import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

type State = {
  release?: string;
  deadline?: string;
  isApply: boolean;
  isTimeOver: boolean;
  isLoading: boolean;
  isError: boolean;
};

const initialState: State = {
  isApply: false,
  isTimeOver: true,
  isLoading: false,
  isError: false,
};

// 非同期通信
export const judgePeriodOver = createAsyncThunk(
  'judgePeriodOver',
  async (params: { period: string }) => {
    const { period } = params;
    // await new Promise(resolve => setTimeout(resolve, 3000)); // 3秒の待ち時間
    const server = `${window.location.protocol}//${window.location.host}/`;
    const result = await axios.head(server).then(res => {
      const timeover = period ? new Date(res.headers.date) >= new Date(period) : false;
      return { timeover };
    });
    return (await result) as { timeover?: boolean };
  }
);

const application = createSlice({
  name: 'application',

  initialState,

  reducers: {
    // 締切の更新
    updateDeadline: (state, action) => {
      const result: {
        deadline: string;
      } = {
        deadline: action.payload,
      };
      return {
        ...state,
        ...result,
      };
    },
    // 応募権の更新
    updateApply: (state, action) => {
      const result: {
        isApply: boolean;
      } = {
        isApply: action.payload,
      };
      return {
        ...state,
        ...result,
      };
    },
  },

  extraReducers: builder => {
    // https://redux-toolkit.js.org/api/createAsyncThunk#return-value
    builder.addCase(judgePeriodOver.pending, state => {
      return {
        ...state,
        isLoading: true,
        isError: false,
      };
    });
    builder.addCase(judgePeriodOver.fulfilled, (state, action) => {
      const result: {
        isLoading: boolean;
        isError: boolean;
        isTimeOver?: boolean;
      } = {
        isLoading: false,
        isError: false,
        isTimeOver: action.payload.timeover,
      };
      return {
        ...state,
        ...result,
      };
    });
    builder.addCase(judgePeriodOver.rejected, (state, action) => {
      return {
        ...state,
        isLoading: false,
        isError: true,
      };
    });
  },
});

// Action Creators
export const { updateDeadline, updateApply } = application.actions;

export default application.reducer;
