/* eslint @typescript-eslint/ban-ts-comment: 0 */
import React, { FC, memo, useEffect, useCallback, useMemo } from 'react';

// router
import { useHistory, useParams } from 'react-router-dom';

// redux
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../store';
import { sendSaveData, confirmError } from '../store/user';
import { updateSave, setRank } from '../store/result';
import { updateAnswer, updateTime, updateFinish, allInitial } from '../store/quiz';
import {
  updateCondition,
  updateOpen,
  updateContent,
  updateClass,
  setCallback,
} from '../store/dialog';

// animation
import { useSpring, animated } from '@react-spring/web';

// assets
import style from '../assets/style/pages/result.module.scss';
import giftStyle from '../assets/style/pages/result-gift.module.scss';
import bg_failed from '../assets/image/play/bg_play_failed.jpg';
import bg_correct from '../assets/image/play/bg_play.jpg';
import blink from '../assets/image/result/blink.svg';
import failed_note from '../assets/image/result/failed_note.svg';
import comment_0 from '../assets/image/result/comment_0.png';
import comment_1 from '../assets/image/result/comment_1.png';
import comment_2 from '../assets/image/result/comment_2.png';
import comment_3 from '../assets/image/result/comment_3.png';
import comment_4 from '../assets/image/result/comment_4.png';
import rank_1 from '../assets/image/result/rank_1.svg';
import rank_2 from '../assets/image/result/rank_2.svg';
import rank_3 from '../assets/image/result/rank_3.svg';
import rank_4 from '../assets/image/result/rank_4.svg';
import title_my_order from '../assets/image/result/title_my_order.svg';
import title_correct_order from '../assets/image/result/title_correct_order.svg';
import txt_replay from '../assets/image/result/txt_replay.svg';
import ico_twitter from '../assets/image/result/ico_twitter.svg';
import titleApply from '../assets/image/apply/title.svg';
import imgApply from '../assets/image/apply/present.svg';
import gift from '../assets/image/gift.png';

// component
import { Series } from '../components/series';
import { Bounce } from '../components/bounce';
import { Character } from '../components/character';

// type
import { SaveData } from '../@types/saveData';
import { Question } from '../@types/question';

// function component
interface ParamTypes {
  id: string;
}

export const Result: FC = () => {
  const dispatch = useDispatch();

  const history = useHistory();
  const { id } = useParams<ParamTypes>();
  const myId = id;

  // 出題の情報を取得
  const list = useSelector((state: RootState) => state.question.list ?? []) as Question[];
  const currentQuestion = useMemo(() => {
    return list.find(item => item.quizId === myId);
  }, [list, myId]);
  const isChallenge = currentQuestion?.release;
  const musicName = currentQuestion?.music ?? '';

  // 挑戦結果の情報を取得
  const { result } = useSelector((state: RootState) => state.quiz);

  const { userId, save, isError, isLoading } = useSelector((state: RootState) => state.user);
  const { rankIndex, rank, data } = useSelector((state: RootState) => state.result);

  // プレゼント応募権
  const sendMessages = (time?: number) => {
    dispatch(updateCondition(giftStyle.applyGift));
    const blink_present = (
      <>
        <span className={[style.blink, giftStyle.blinkPresentA].join(' ')}>
          <img className={giftStyle.blinkPresentAimg} src={blink} alt="" />
        </span>
        <span className={[style.blink, giftStyle.blinkPresentB].join(' ')}>
          <img className={giftStyle.blinkPresentBimg} src={blink} alt="" />
        </span>
      </>
    );
    dispatch(
      updateContent(
        <>
          <img className={giftStyle.applyGift} src={imgApply} alt="" width="201" height="133" />
          <img
            className={giftStyle.applyTitle}
            src={titleApply}
            alt="プレゼント応募権GET！"
            width="219"
            height="59"
          />
          <figure>
            <img src={gift} alt="" width="325" height="123" />
          </figure>
          <p className={giftStyle.giftFigcaption}>
            <small>番組オリジナル ワイヤレススピーカーを</small>
            <br />
            <span className="u-textAccent">
              抽選<strong>3</strong>名様
            </span>
            にプレゼント!!
          </p>
          {blink_present}
        </>
      )
    );
    dispatch(updateClass([giftStyle.applyGiftMessage]));
    dispatch(updateOpen(true));

    // Google Analytics
    // @ts-ignore
    window.gtagEvent('successful_challenge', { event_label: 'correct_time', value: time });
  };

  // ロード完了で一度実行
  useEffect(() => {
    // 回答結果をリセット
    dispatch(updateAnswer([]));

    // チャレンジ済
    const doneCorrected = save?.find(q => q.isCorrect);
    if (isChallenge && !doneCorrected) {
      // ダイアログへメッセージを設定
      dispatch(updateContent(<p>結果を保存できませんでした</p>));
      // ダイアログを閉じるときエラー解除
      dispatch(
        setCallback((isOpen: boolean): void => {
          if (!isOpen) dispatch(confirmError());
        })
      );
    }

    // データセーブ
    const doneAnswer = save?.find(q => q.quizId === myId); // リトライ回答フラグ
    const doneCorrect = save?.find(q => q.isCorrect === true); // 既に正解フラグ
    const myData = (result.quizId === '' ? doneAnswer : result) as SaveData;
    // ゲーム結果が取得できていない場合は問題一覧へリダイレクト
    if (!myData) history.push(`/`);
    // リザルト画面描画用データにゲーム結果を反映
    dispatch(updateSave(myData));
    // 保存実行判定
    if (isChallenge && !doneAnswer && myData && userId) {
      dispatch(sendSaveData({ userId, data: save ? [...save, myData] : [myData] }));
      if (result.isCorrect && !doneCorrect) sendMessages(myData.time);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // リザルトの結果をみて実行
  useMemo(() => {
    // ランク判定
    const judgeRank = (time: number) => {
      for (const i in rankIndex) {
        const valid = rankIndex[i] > time;
        if (!valid) return +i;
      }
    };
    const myRank = data ? judgeRank(data.isCorrect === true ? data.time : 101) : 0;
    if (data) dispatch(setRank(myRank));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  // 画像素材
  const commentImages = [comment_0, comment_1, comment_2, comment_3, comment_4];
  const rankImages = ['', rank_1, rank_2, rank_3, rank_4];

  // ツイートシェアボタン
  const SnsShareButton = memo(() => {
    // ツイートシェアリンク
    const createTweet = (rank: number, time: number): string => {
      const baseURL = 'https://twitter.com/share?';
      const lineURL = process.env.REACT_APP_LINE_ACCOUNT;

      const rankText = [
        'ざんねん！もう一度チャレンジしてみてね💨',
        'ギリギリ正解👍あぶなかったね…💦',
        'Good👌！音感力中級者！💨',
        'Great🙌！音感力上級者！💨',
        'Excellent👏 音感力神レベル！💯',
      ];

      const resultText = rank === 0 ? `不正解でした🌀` : `${time}秒で正解しました🎹🎶`;

      const text = [
        `#LINEでメロディシャッフリン 「${musicName}」に${resultText}`,
        `${rankText[rank]} `,
        ``,
        `#TBS 『#オトラクション』公式LINEからゲームにチャレンジしよう💪 `,
        `豪華プレゼントGETのチャンス❗️👀🎁✨ `,
        ``,
      ]
        .join('%0a')
        .replace(/\s/g, '%20')
        .replace(/#/g, '%20%23');
      return `${baseURL}url=${lineURL}&text=${text}`;
    };
    return (
      // eslint-disable-next-line react/jsx-no-target-blank
      <Bounce
        className={style.share}
        onClick={() => {
          // Google Analytics
          // @ts-ignore
          window.gtagEvent('share', { item_id: musicName, method: 'Twitter' });
          // tweet
          // eslint-disable-next-line no-restricted-globals
          location.href = createTweet(rank, data?.time ?? 100);
        }}
      >
        <img src={ico_twitter} alt="Twitter" />
        シェア
      </Bounce>
    );
  });

  useMemo(() => {
    dispatch(updateTime(0));
    dispatch(updateFinish(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId, save]);

  // エラー解除
  useMemo(() => {
    if (isError === true && isLoading === false) {
      dispatch(updateCondition('rejectedSave'));
      dispatch(updateOpen(true));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isError]);

  // もう一度あそぶ
  const actRetry = useCallback(() => {
    // プレイデータをリセット
    dispatch(allInitial());
    // プレイ画面へ遷移
    history.push(`/play/${id}`);
  }, [dispatch, history, id]);

  const labelColors = ['', '#004F72', '#9B086E', '#484848', '#966200'];
  const timeColors = ['', '#004F72', '#9B086E', '#484848', '#6C5300'];
  const bgImage = rank === 0 ? bg_failed : bg_correct;
  // console.log(typeof rank);

  // 表示
  const resultContent = () => {
    const highLank_decoration = (
      <>
        <Character type={0} wrapClass={style.charaBluePosition} imgClass={style.charaBlue} />
        <Character type={1} wrapClass={style.charaRedPosition} imgClass={style.charaRed} />
      </>
    );
    const lowLank_decoration = (
      <Character type={1} wrapClass={style.charaRedPosition_low} imgClass={style.charaRed} />
    );
    const blink_blink = (
      <>
        <span className={[style.blink, style.blinkA].join(' ')}>
          <img src={blink} alt="" />
        </span>
        <span className={[style.blink, style.blinkB].join(' ')}>
          <img src={blink} alt="" />
        </span>
      </>
    );
    const blink_blink_blink = (
      <>
        <span className={[style.blink, style.blinkC].join(' ')}>
          <img src={blink} alt="" />
        </span>
        <span className={[style.blink, style.blinkD].join(' ')}>
          <img src={blink} alt="" />
        </span>
      </>
    );

    const corrected = (
      <div className={[style.correctedResult, style['rank' + rank]].join(' ')}>
        <div className={style.circle}>
          <p className={style.label} style={{ color: `${labelColors[rank]}` }}>
            あなたの記録
          </p>
          <p style={{ color: `${timeColors[rank]}` }}>
            <span className={style.time}>{data?.time}</span>
            <span className={style.unit}>秒</span>
          </p>
        </div>
        <img className={style.rank} src={rankImages[rank]} alt="" />
        {rank > 2 ? highLank_decoration : lowLank_decoration}
        {rank > 1 ? blink_blink : undefined}
        {rank === 4 ? blink_blink_blink : undefined}
      </div>
    );
    const order = (order?: ('A' | 'B' | 'C' | 'D' | 'E')[]) => {
      return order?.map((item, index, myOrder) => {
        return myOrder.length === index + 1 ? (
          <React.Fragment key={`my_${index}`}>{item}</React.Fragment>
        ) : (
          <React.Fragment key={`a_${index}`}>
            {item}
            <span className={style.arrow}>→</span>
          </React.Fragment>
        );
      });
    };
    const failed = (
      <div className={style.failedResult}>
        <div className={style.data}>
          <img className={style.orderTitle} src={title_my_order} alt="Twitter" />
          <p className={style.order}>{order(data?.myOrder)}</p>
          <img className={style.orderTitle} src={title_correct_order} alt="Twitter" />
          <p className={style.order}>{order(data?.correctOrder)}</p>
          <Character
            type={0}
            wrapClass={style.charaBluePosition_failed}
            imgClass={style.charaBlue}
          />
          <span className={[style.failed_note, style.noteA].join(' ')}>
            <img src={failed_note} alt="" />
          </span>
          <span className={[style.failed_note, style.noteB].join(' ')}>
            <img src={failed_note} alt="" />
          </span>
        </div>
      </div>
    );
    return data?.isCorrect === true ? corrected : data?.isCorrect === false ? failed : '';
  };

  // effect animation
  const { spring_comment } = useSpring({
    from: { spring_comment: 0 },
    to: { spring_comment: 1 },
    config: { duration: 500 },
    delay: 500,
  });

  // Google Analytics
  useEffect(() => {
    document.title = `${musicName} プレイ結果`;
    // @ts-ignore
    window.gtagPageView(history.location.pathname);
  }, [musicName, history]);

  const returnButton = useMemo(() => {
    const text = !currentQuestion?.release ? '練習問題一覧へ' : 'TOPへ';
    return (
      <Bounce
        className={style.return}
        onClick={() => (!currentQuestion?.release ? history.go(-2) : history.push('/'))}
      >
        {text}
      </Bounce>
    );
  }, [currentQuestion?.release, history]);

  const challenge = (!currentQuestion?.release ? 0 : myId.match(/1$/) ? 1 : 2) as 0 | 1 | 2;

  return (
    <article className={`l-page ${style.result}`} style={{ backgroundImage: `url(${bgImage})` }}>
      <header>
        <h1>
          <Series level={currentQuestion?.level ?? 1} challenge={challenge} />
        </h1>
      </header>
      <div className={style.body}>
        <animated.img
          style={{
            transformOrigin: 'bottom center',
            opacity: spring_comment.to({
              range: [0, 0.4, 1],
              output: [0, 1, 1],
            }),
            y: spring_comment.to({
              range: [0, 0.4, 0.66, 1],
              output: [-15, -20, 0, 0],
            }),
            scaleX: spring_comment.to({
              range: [0, 0.4, 0.7, 0.85, 1],
              output: [1, 0.98, 1, 1.05, 1],
            }),
            scaleY: spring_comment.to({
              range: [0, 0.66, 0.8, 1],
              output: [1, 1, 0.98, 1],
            }),
          }}
          className={style.comment}
          src={commentImages[rank]}
          alt=""
        />
        {resultContent()}
      </div>
      <footer className={style.footer}>
        <div className={style.navi}>
          <Bounce className={style.btnReplay} onClick={actRetry}>
            <img src={txt_replay} alt="もう一回あそぶ" />
            {!currentQuestion?.release ? '' : <span>結果は更新されません</span>}
          </Bounce>
          <SnsShareButton></SnsShareButton>
        </div>
        {returnButton}
      </footer>
    </article>
  );
};
