import { FC, useMemo } from 'react';

// router
import { useHistory } from 'react-router-dom';

// assets
import style from '../assets/style/components/pagination.module.scss';
import arrow from '../assets/image/archive/ico_arrow.svg';

// function component
type Props = {
  filter: number;
  current: number;
  total: number;
  max: number;
};

export const Pagination: FC<Props> = (props: Props) => {
  const history = useHistory();

  const { filter, current, total, max } = props;

  // カレントを前後にずらす
  const shiftCurrent = (direction: number) => {
    const result = current + direction;
    history.push(`/archive/${filter}/${result}`);
  };

  // ページ数
  const pages = useMemo(() => Math.ceil(total / max), [max, total]);

  return (
    <div className={style.pagination}>
      <button
        type="button"
        className={style.previous}
        onClick={() => shiftCurrent(-1)}
        disabled={current === 1}
      >
        <img src={arrow} alt="前へ" />
      </button>
      <p className={style.page}>
        {current}
        <small> / {pages}</small>
      </p>
      <button
        type="button"
        className={style.next}
        onClick={() => shiftCurrent(1)}
        disabled={current === pages}
      >
        <img src={arrow} alt="次へ" />
      </button>
    </div>
  );
};
