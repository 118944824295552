/* eslint @typescript-eslint/ban-ts-comment: 0 */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { SaveData } from '../@types/saveData';

// lodash
import { isEqual } from 'lodash-es';

type State = {
  userId?: string;
  save?: SaveData[];
  isLoading: boolean;
  isError: boolean;
  message: string;
};

const initialState: State = {
  isLoading: false,
  isError: false,
  message: '',
};

// 非同期通信
type SaveDataResponse = {
  data?: SaveData[];
  error?: string;
};

// プレイデータを取得
export enum LoadMessage {
  Pending = '読込しています',
  Fulfilled = '読込できました',
  Rejected = '読込に失敗しました',
}
export const getSaveData = createAsyncThunk(
  'getSaveData',
  async (params: { userId: string; data?: SaveData[] }) => {
    // await new Promise(resolve => setTimeout(resolve, 3000)); // 3秒の待ち時間
    const url = `${process.env.REACT_APP_API_ENDPOINT}/user`;
    const apiKey = process.env.REACT_APP_API_KEY || '';

    const result = await fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'x-api-key': apiKey,
        'x-quiz-authorization': params.userId,
      },
    }).then((res: Response) => res.json());

    // API GETリクエストの詳細を解析
    if (params.data) {
      // @ts-ignore
      window.gtagEvent(
        isEqual(params.data, result.data) ? 'api_get_notUpdate' : 'api_get_isUpdate'
      );
    }
    // @ts-ignore
    else window.gtagEvent('login', { method: 'LIFF' });

    return (await result) as SaveDataResponse;
  }
);
// プレイデータを送信
export enum SaveMessage {
  Pending = '保存しています',
  Fulfilled = '保存できました',
  Rejected = '保存に失敗しました',
}
export const sendSaveData = createAsyncThunk(
  'sendSaveData',
  async (params: { userId: string; data: SaveData[] }) => {
    // await new Promise(resolve => setTimeout(resolve, 3000)); // 3秒の待ち時間
    const url = `${process.env.REACT_APP_API_ENDPOINT}/user`;
    const apiKey = process.env.REACT_APP_API_KEY || '';
    const body = JSON.stringify({ data: params.data });
    const result = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'x-api-key': apiKey,
        'x-quiz-authorization': params.userId,
      },
      body,
    }).then((res: Response) => res.json());
    return { ...((await result) as SaveDataResponse), ...params };
  }
);

const user = createSlice({
  name: 'user',

  initialState,

  reducers: {
    // LINE USER ID を保存
    updateUserId: (state, action) => {
      state.userId = action.payload as string;
    },

    // 結果を追加
    addResult: (state, action) => {
      return {
        ...state,
        save: state.save ? [...state.save, action.payload] : [action.payload],
      };
    },

    // エラー確認済み
    confirmError: state => {
      state.isError = false;
    },
  },
  extraReducers: builder => {
    // https://redux-toolkit.js.org/api/createAsyncThunk#return-value
    // プレイデータを取得
    builder.addCase(getSaveData.pending, state => {
      return {
        ...state,
        isLoading: true,
        message: LoadMessage.Pending,
      };
    });
    builder.addCase(getSaveData.fulfilled, (state, action) => {
      const payload = action.payload?.data;
      const result: {
        isLoading: boolean;
        isError: boolean;
        save?: SaveData[];
        message: string;
      } = {
        isLoading: false,
        isError: action.payload?.error ? true : false,
        message: LoadMessage.Fulfilled,
      };
      // エラーをアクセス解析で測定
      // @ts-ignore
      if (action.payload?.error) window.gtagEvent('api_post_error');
      if (payload) result.save = payload;
      return {
        ...state,
        ...result,
      };
    });
    builder.addCase(getSaveData.rejected, (state, action) => {
      // エラーをアクセス解析で測定
      // @ts-ignore
      window.gtagEvent('api_get_error');
      return {
        ...state,
        isLoading: false,
        isError: true,
        message: LoadMessage.Rejected,
      };
    });

    // プレイデータを送信
    builder.addCase(sendSaveData.pending, state => {
      return {
        ...state,
        isLoading: true,
        message: SaveMessage.Pending,
      };
    });
    builder.addCase(sendSaveData.fulfilled, (state, action) => {
      const payload = action.payload?.data;
      const result: {
        save?: SaveData[];
        isLoading: boolean;
        isError: boolean;
        message: string;
      } = {
        isLoading: false,
        isError: action.payload?.error ? true : false,
        message: !action.payload?.error ? SaveMessage.Fulfilled : SaveMessage.Rejected,
      };
      // エラーをアクセス解析で測定
      // @ts-ignore
      if (action.payload?.error) window.gtagEvent('api_post_error');
      if (payload) result.save = payload;
      return {
        ...state,
        ...result,
      };
    });
    builder.addCase(sendSaveData.rejected, (state, action) => {
      // エラーをアクセス解析で測定
      // @ts-ignore
      window.gtagEvent('api_post_error');
      return {
        ...state,
        isLoading: false,
        isError: true,
        message: SaveMessage.Rejected,
      };
    });
  },
});

// Action Creators
export const { updateUserId, addResult, confirmError } = user.actions;

// Reducer
export default user.reducer;
