/* eslint @typescript-eslint/ban-ts-comment: 0 */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { SaveData } from '../@types/saveData';

type State = {
  timeLimit: 100;
  isCountdown: boolean;
  isFinish: boolean;
  currentPlay: number | null;
  musicValid: boolean[];
  loadedmetadata: boolean[];
  answer: (string | null)[];
  time: number;
  step: number;
  result: SaveData;
  isLoading: boolean;
  isError: boolean;
};

const initialState: State = {
  timeLimit: 100,
  isCountdown: false,
  isFinish: false,
  currentPlay: null,
  musicValid: new Array<boolean>(6).fill(false),
  loadedmetadata: new Array<boolean>(6).fill(false),
  answer: [],
  time: 0,
  step: 0,
  result: {
    quizId: '',
    isCorrect: false,
    myOrder: [],
    correctOrder: [],
    time: 100,
  },
  isLoading: false,
  isError: false,
};

// 非同期通信
export const judgeRelease = createAsyncThunk('judgeRelease', async (params: { period: string }) => {
  const { period } = params;
  // await new Promise(resolve => setTimeout(resolve, 3000)); // 3秒の待ち時間
  const server = `${window.location.protocol}//${window.location.host}/`;
  const result = await axios.head(server).then(res => {
    const valid = period ? new Date(res.headers.date) >= new Date(period) : false;
    return {
      isReleased: valid,
    };
  });
  return (await result) as { isReleased?: boolean };
});

const quiz = createSlice({
  name: 'quiz',

  initialState,

  reducers: {
    // ステップ
    updateStep: (state, action) => {
      state.step = action.payload;
    },
    // 答え合わせ
    updateFinish: (state, action) => {
      state.isFinish = action.payload;
    },
    // プレイ中カウント判定を更新
    updateCountdown: (state, action) => {
      state.isCountdown = action.payload;
    },
    // 再生中
    updateCurrentPlay: (state, action) => {
      state.currentPlay = action.payload;
    },
    // 再生許可
    updateMusicValid: (state, action) => {
      state.musicValid[action.payload] = true;
    },
    // 再生許可
    setDisabled: (state, action) => {
      const statusList = new Array<boolean>(6).fill(false);
      statusList[action.payload] = true;
      state.musicValid = statusList;
    },
    // 再生許可
    clearDisabled: state => {
      state.musicValid = new Array<boolean>(6).fill(true);
    },
    // 音声の読込完了
    updateLoadedmetadata: (state, action) => {
      state.loadedmetadata[action.payload] = true;
    },
    // 回答を更新
    updateAnswer: (state, action) => {
      state.answer = action.payload;
    },
    // 経過時間を更新
    updateTime: (state, action) => {
      state.time = action.payload;
    },
    // 結果
    updateResult: (state, action) => {
      state.result = {
        quizId: action.payload.quizId,
        isCorrect: action.payload.isCorrect,
        myOrder: action.payload.myOrder,
        correctOrder: action.payload.correctOrder,
        time: action.payload.time,
      };
    },
    allInitial: state => {
      Object.keys(initialState).forEach(key => {
        // @ts-ignore
        state[key] = initialState[key];
      });
    },
  },
  extraReducers: builder => {
    // https://redux-toolkit.js.org/api/createAsyncThunk#return-value

    builder.addCase(judgeRelease.fulfilled, (state, action) => {
      const result: {
        isLoading: boolean;
        isError: boolean;
      } = {
        isLoading: false,
        isError: !action.payload.isReleased,
      };
      return {
        ...state,
        ...result,
      };
    });
    builder.addCase(judgeRelease.rejected, (state, action) => {
      return {
        ...state,
        isLoading: false,
        isError: true,
      };
    });
  },
});

// Action Creators
export const {
  updateStep,
  updateFinish,
  updateCountdown,
  updateCurrentPlay,
  updateMusicValid,
  setDisabled,
  clearDisabled,
  updateLoadedmetadata,
  updateAnswer,
  updateTime,
  updateResult,
  allInitial,
} = quiz.actions;

// Reducer
export default quiz.reducer;
