import { FC, useState, useMemo } from 'react';

// redux
import { useSelector } from 'react-redux';
import { RootState } from '../store';

// assets
import style from '../assets/style/layout/toast.module.scss';

// function component
export const Toast: FC = () => {
  const { isLoading, message } = useSelector((state: RootState) => state.user);

  const [className, setClassName] = useState<string[]>([style.toast]);

  useMemo(() => {
    setClassName(isLoading ? [style.toast, style.isActive] : [style.toast]);
  }, [isLoading]);

  return (
    <div className={className.join(' ')}>
      <p className={style.message}>{message}</p>
    </div>
  );
};
