import { createSlice } from '@reduxjs/toolkit';
import { SaveData } from '../@types/saveData';

type State = {
  rankIndex: [101, 81, 61, 31, 0];
  rank: 0 | 1 | 2 | 3 | 4;
  data?: SaveData;
};

const initialState: State = {
  rankIndex: [101, 81, 61, 31, 0],
  rank: 0,
};

const result = createSlice({
  name: 'result',

  initialState,

  reducers: {
    updateSave: (state, action) => {
      const result: {
        data: SaveData;
      } = {
        data: action.payload,
      };
      return {
        ...state,
        ...result,
      };
    },
    setRank: (state, action) => {
      const result: {
        rank: 0 | 1 | 2 | 3 | 4;
      } = {
        rank: action.payload,
      };
      return {
        ...state,
        ...result,
      };
    },
  },
});

// Action Creators
export const { updateSave, setRank } = result.actions;

// Reducer
export default result.reducer;
