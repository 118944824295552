import { FC } from 'react';

// router
import { BrowserRouter, Route, Switch } from 'react-router-dom';

// pages
import { Home } from '../pages/home';
import Play from '../pages/play';
import { Result } from '../pages/result';
import { Archive } from '../pages/archive';

// layout
import { Toast } from '../layout/toast';
import { Dialog } from '../layout/dialog';

// img:root
import bg_round from '../assets/image/bg_round.svg';
import character_blue from '../assets/image/character_blue.png';
import character_red_01 from '../assets/image/character_red_01.png';
import character_red_02 from '../assets/image/character_red_02.png';
import gift from '../assets/image/gift.png';
import ico_guide from '../assets/image/ico_guide.svg';
import present from '../assets/image/present.svg';

// img:apply
import apply_bg_light from '../assets/image/apply/bg_light.png';
import apply_present from '../assets/image/apply/present.svg';
import apply_title from '../assets/image/apply/title.svg';

// img:archive
import archive_badge_new from '../assets/image/archive/badge_new.svg';
import archive_btn_back from '../assets/image/archive/btn_back.svg';
import archive_ico_arrow from '../assets/image/archive/ico_arrow.svg';
import archive_level_star_01_inactive from '../assets/image/archive/level_star_01_inactive.svg';
import archive_level_star_01 from '../assets/image/archive/level_star_01.svg';
import archive_level_star_02_inactive from '../assets/image/archive/level_star_02_inactive.svg';
import archive_level_star_02 from '../assets/image/archive/level_star_02.svg';
import archive_level_star_03 from '../assets/image/archive/level_star_03.svg';
import archive_txt_filter_info from '../assets/image/archive/txt_filter_info.svg';
import archive_title from '../assets/image/archive/txt_title.svg';

// img:guide
import guide_order1 from '../assets/image/guide/order1.svg';
import guide_order2 from '../assets/image/guide/order2.svg';
import guide_order3 from '../assets/image/guide/order3.svg';
import guide_order4 from '../assets/image/guide/order4.svg';
import guide_order5 from '../assets/image/guide/order5.svg';
import guide_title_guide from '../assets/image/guide/title_guide.svg';
import guide_visual1 from '../assets/image/guide/visual1.png';
import guide_visual2 from '../assets/image/guide/visual2.png';
import guide_visual3 from '../assets/image/guide/visual3.png';
import guide_visual4 from '../assets/image/guide/visual4.png';
import guide_visual5 from '../assets/image/guide/visual5.png';

// img:home
import home_bg_bottom from '../assets/image/home/bg_bottom.png';
import home_bg_giftHeader from '../assets/image/home/bg_giftHeader.svg';
import home_bg_home from '../assets/image/home/bg_home.jpg';
import home_bg_top from '../assets/image/home/bg_top.png';
import home_gloss_b from '../assets/image/home/gloss_b.svg';
import home_gloss_t from '../assets/image/home/gloss_t.svg';
import home_ico_touch from '../assets/image/home/ico_touch.svg';
import home_logo from '../assets/image/home/logo.png';
import home_present from '../assets/image/home/present.svg';
import home_txt_apply from '../assets/image/home/txt_apply.svg';
import home_txt_lead from '../assets/image/home/txt_lead.svg';
import home_txt_training from '../assets/image/home/txt_training.svg';

import home_challenge_correct from '../assets/image/home/challenge/correct.png';
import home_challenge_failed from '../assets/image/home/challenge/failed.png';
import home_challenge_txt_series1 from '../assets/image/home/challenge/txt_series1.svg';
import home_challenge_txt_series2 from '../assets/image/home/challenge/txt_series2.svg';
import home_challenge_txt_subtitle from '../assets/image/home/challenge/txt_subtitle.png';
import home_challenge_txt_title from '../assets/image/home/challenge/txt_title.svg';

import home_warmup_star_current from '../assets/image/home/warmup/star_current.svg';
import home_warmup_star_disable from '../assets/image/home/warmup/star_disable.svg';
import home_warmup_txt_link from '../assets/image/home/warmup/txt_link.svg';
import home_warmup_txt_subtitle from '../assets/image/home/warmup/txt_subtitle.png';
import home_warmup_txt_title from '../assets/image/home/warmup/txt_title.svg';

// img:play
import play_bg_play_failed from '../assets/image/play/bg_play_failed.jpg';
import play_bg_play from '../assets/image/play/bg_play.jpg';
import play_btn_complete_off from '../assets/image/play/btn_complete_off.png';
import play_btn_complete_on from '../assets/image/play/btn_complete_on.png';
import play_img_corrected from '../assets/image/play/img_corrected.png';
import play_img_failed from '../assets/image/play/img_failed.png';
import play_txt_finish from '../assets/image/play/txt_finish.png';
import play_txt_judging from '../assets/image/play/txt_judging.svg';
import play_txt_music from '../assets/image/play/txt_music.svg';
import play_txt_start from '../assets/image/play/txt_start.png';
import play_txt_timeout from '../assets/image/play/txt_timeout.png';
import play_txt_title1 from '../assets/image/play/txt_title1.svg';
import play_txt_title2 from '../assets/image/play/txt_title2.svg';
import play_txt_title3 from '../assets/image/play/txt_title3.svg';
import play_txt_title4 from '../assets/image/play/txt_title4.svg';
import play_txt_title5 from '../assets/image/play/txt_title5.svg';

// img:play/note
import play_note_blue from '../assets/image/play/note/blue.png';
import play_note_green from '../assets/image/play/note/green.png';
import play_note_purple from '../assets/image/play/note/purple.png';
import play_note_red from '../assets/image/play/note/red.png';
import play_note_yellow from '../assets/image/play/note/yellow.png';
import play_note_current_blue from '../assets/image/play/note/current/blue.png';
import play_note_current_green from '../assets/image/play/note/current/green.png';
import play_note_current_purple from '../assets/image/play/note/current/purple.png';
import play_note_current_red from '../assets/image/play/note/current/red.png';
import play_note_current_yellow from '../assets/image/play/note/current/yellow.png';
import play_note_label_a from '../assets/image/play/note/label/a.png';
import play_note_label_b from '../assets/image/play/note/label/b.png';
import play_note_label_c from '../assets/image/play/note/label/c.png';
import play_note_label_d from '../assets/image/play/note/label/d.png';
import play_note_label_e from '../assets/image/play/note/label/e.png';

// img:result
import result_blink from '../assets/image/result/blink.svg';
import result_circle_1 from '../assets/image/result/circle_1.png';
import result_circle_2 from '../assets/image/result/circle_2.png';
import result_circle_3 from '../assets/image/result/circle_3.png';
import result_circle_4 from '../assets/image/result/circle_4.png';
import result_comment_0 from '../assets/image/result/comment_0.png';
import result_comment_1 from '../assets/image/result/comment_1.png';
import result_comment_2 from '../assets/image/result/comment_2.png';
import result_comment_3 from '../assets/image/result/comment_3.png';
import result_comment_4 from '../assets/image/result/comment_4.png';
import result_crown_3 from '../assets/image/result/crown_3.png';
import result_crown_4 from '../assets/image/result/crown_4.png';
import result_failed_note from '../assets/image/result/failed_note.svg';
import result_ico_twitter from '../assets/image/result/ico_twitter.svg';
import result_rank_1 from '../assets/image/result/rank_1.svg';
import result_rank_2 from '../assets/image/result/rank_2.svg';
import result_rank_3 from '../assets/image/result/rank_3.svg';
import result_rank_4 from '../assets/image/result/rank_4.svg';
import result_title_correct_order from '../assets/image/result/title_correct_order.svg';
import result_title_my_order from '../assets/image/result/title_my_order.svg';
import result_txt_replay from '../assets/image/result/txt_replay.svg';

// component
const images: string[] = [
  bg_round,
  character_blue,
  character_red_01,
  character_red_02,
  gift,
  ico_guide,
  present,
  apply_bg_light,
  apply_present,
  apply_title,
  archive_badge_new,
  archive_btn_back,
  archive_ico_arrow,
  archive_level_star_01_inactive,
  archive_level_star_01,
  archive_level_star_02_inactive,
  archive_level_star_02,
  archive_level_star_03,
  archive_txt_filter_info,
  archive_title,
  guide_order1,
  guide_order2,
  guide_order3,
  guide_order4,
  guide_order5,
  guide_title_guide,
  guide_visual1,
  guide_visual2,
  guide_visual3,
  guide_visual4,
  guide_visual5,
  home_bg_bottom,
  home_bg_giftHeader,
  home_bg_home,
  home_bg_top,
  home_gloss_b,
  home_gloss_t,
  home_ico_touch,
  home_logo,
  home_present,
  home_txt_apply,
  home_txt_lead,
  home_txt_training,
  home_challenge_correct,
  home_challenge_failed,
  home_challenge_txt_series1,
  home_challenge_txt_series2,
  home_challenge_txt_subtitle,
  home_challenge_txt_title,
  home_warmup_star_current,
  home_warmup_star_disable,
  home_warmup_txt_link,
  home_warmup_txt_subtitle,
  home_warmup_txt_title,
  play_bg_play_failed,
  play_bg_play,
  play_btn_complete_off,
  play_btn_complete_on,
  play_img_corrected,
  play_img_failed,
  play_txt_finish,
  play_txt_judging,
  play_txt_music,
  play_txt_start,
  play_txt_timeout,
  play_txt_title1,
  play_txt_title2,
  play_txt_title3,
  play_txt_title4,
  play_txt_title5,
  play_note_blue,
  play_note_green,
  play_note_purple,
  play_note_red,
  play_note_yellow,
  play_note_current_blue,
  play_note_current_green,
  play_note_current_purple,
  play_note_current_red,
  play_note_current_yellow,
  play_note_label_a,
  play_note_label_b,
  play_note_label_c,
  play_note_label_d,
  play_note_label_e,
  result_blink,
  result_circle_1,
  result_circle_2,
  result_circle_3,
  result_circle_4,
  result_comment_0,
  result_comment_1,
  result_comment_2,
  result_comment_3,
  result_comment_4,
  result_crown_3,
  result_crown_4,
  result_failed_note,
  result_ico_twitter,
  result_rank_1,
  result_rank_2,
  result_rank_3,
  result_rank_4,
  result_title_correct_order,
  result_title_my_order,
  result_txt_replay,
];
const template = (src: string, index: number): JSX.Element => (
  <link key={index} rel="preload" href={src} as="image" />
);

export const Private: FC = () => {
  return (
    <BrowserRouter>
      {images.map((img, index) => template(img, index))}
      <Switch>
        <Route exact path="/" component={Home} />
        <Route exact path="/play/:id" component={Play} />
        <Route exact path="/result/:id" component={Result} />
        <Route exact path="/archive/:filter/:page" component={Archive} />
      </Switch>
      <Toast />
      <Dialog />
    </BrowserRouter>
  );
};
