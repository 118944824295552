import { combineReducers, configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';

import application from './application';
import question from './question';
import quiz from './quiz';
import user from './user';
import dialog from './dialog';
import result from './result';
import newBadge from './newBadge';

const reducer = combineReducers({
  application: application,
  question,
  quiz,
  user,
  dialog,
  result,
  newBadge,
});

const middleware = getDefaultMiddleware({ serializableCheck: false });
export const store = configureStore({ reducer, middleware });

export type RootState = ReturnType<typeof reducer>;
