/* eslint @typescript-eslint/ban-ts-comment: 0 */
import { FC, useEffect, useMemo } from 'react';

// router
import { useHistory, useParams } from 'react-router-dom';

// redux
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../store';
import { allInitial } from '../store/quiz';
import { updateAnimation } from '../store/newBadge';

// assets
import style from '../assets/style/pages/archive.module.scss';
import title from '../assets/image/archive/txt_title.svg';
import info from '../assets/image/archive/txt_filter_info.svg';
import level_01 from '../assets/image/archive/level_star_01.svg';
import level_02 from '../assets/image/archive/level_star_02.svg';
import level_03 from '../assets/image/archive/level_star_03.svg';
import badge_new from '../assets/image/archive/badge_new.svg';

// component
import { Pagination } from '../components/pagination';
import { Filter } from '../components/filter';
import { GuideButton } from '../components/guideButton';
import { Character } from '../components/character';
import { Bounce } from '../components/bounce';

// type
import { Question } from '../@types/question';

// function component
interface ParamTypes {
  filter: string;
  page: string;
}

export const Archive: FC<ParamTypes> = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { filter, page } = useParams<ParamTypes>();

  const max = 5; // 1ページに表示させる件数

  // 出題の情報を取得
  const allQuestions = useSelector((state: RootState) => state.question.list);
  const archiveQuestions = allQuestions.filter(item => !item.release);
  // 最新の更新日（NEWバッヂ用）
  const newerUpdate = archiveQuestions[0].quizId.replace(
    /([0-9]{4}-[0-9]{2}-[0-9]{2})-[0-9]/g,
    '$1'
  );

  // ロード完了で一度実行
  useEffect(() => {
    // ゲームのプレイ中ステータスをリセット
    dispatch(allInitial());
  }, [dispatch]);

  // Google Analytics
  useEffect(() => {
    document.title = `練習問題一覧`;
    // @ts-ignore
    window.gtagPageView(history.location.pathname);
  }, [history]);

  // NEWバッヂのエフェクト
  useEffect(() => {
    dispatch(updateAnimation(true));
    setTimeout(() => {
      dispatch(updateAnimation(false));
    }, 400);
  });

  // 一覧のカード
  const questionItem = (item: Question, i: number) => {
    const releaseDate = new Date(item.quizId.replace(/([0-9]{4}-[0-9]{2}-[0-9]{2})-[0-9]/g, '$1'));
    const update = new RegExp(newerUpdate, 'g');
    const options = [level_01, level_02, level_03];
    const alt = ['レベル1', 'レベル2', 'レベル3'];
    // NEWバッジ
    const newer = (
      <img
        className={`new ${style.new}`}
        src={badge_new}
        alt="new"
        style={item.quizId.match(update) ? {} : { display: 'none' }}
      />
    );
    const release = {
      year: releaseDate.getFullYear() + '',
      month: releaseDate.getMonth() + 1 + '',
      date: releaseDate.getDate() + '',
    };
    return (
      <Bounce
        className={style.item}
        type="button"
        key={i}
        onClick={() => {
          history.push(`/play/${item.quizId}`);
        }}
      >
        <span className={style.music}>{item.music}</span>
        <span className={style.detail}>
          <img className={style.level} src={options[item.level - 1]} alt={alt[item.level - 1]} />
          <small className={style.update}>
            {release.year}年{release.month}月{release.date}日 追加
          </small>
        </span>
        {newer}
      </Bounce>
    );
  };

  const resultList = useMemo(() => {
    return !+filter ? archiveQuestions : archiveQuestions?.filter(item => item.level === +filter);
  }, [filter, archiveQuestions]);

  const currentPage = useMemo(() => {
    const start = (+page - 1) * max;
    const end = start + max;
    return resultList?.slice(start, end);
  }, [page, resultList]);

  // ページ遷移時、スクロール位置を戻す
  window.scrollTo(0, 0);

  return (
    <article className={style.archive}>
      <header className={style.header}>
        <button className={style.return} onClick={() => history.push(`/`)}>
          <p>TOPへ</p>
        </button>
        <h1>
          <img className={style.title} src={title} alt="練習問題一覧" />
        </h1>
        <GuideButton />
      </header>
      <div className={style.body}>
        <Character type={1} wrapClass={style.charaRedPosition} imgClass={style.charaRed} />
        <h2>
          <img className={style.info} src={info} alt="難易度で問題を絞りこめるよ!" />
        </h2>
        <Filter current={+filter} />
        <div className={style.list}>
          {currentPage ? (
            currentPage.map((item: Question, i: number) => questionItem(item, i))
          ) : (
            <p className={style.empty}>この条件の問題は0件です</p>
          )}
        </div>
        <Character type={0} wrapClass={style.charaBluePosition} imgClass={style.charaBlue} />
      </div>
      <footer>
        <Pagination filter={+filter} current={+page} total={resultList?.length ?? 0} max={max} />
      </footer>
    </article>
  );
};
