import { FC, useEffect } from 'react';

// redux
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../store';
import {
  updateCountdown,
  updateMusicValid,
  updateCurrentPlay,
  updateLoadedmetadata,
} from '../store/quiz';

// assets
import style from '../assets/style/components/music.module.scss';
import title from '../assets/image/play/txt_music.svg';

// function component
type Props = {
  storage?: string;
  music?: string;
};

export const Music: FC<Props> = (props: Props) => {
  const dispatch = useDispatch();
  const myIndex = 5;

  const src = props.storage + 'all.mp3';

  const { step, currentPlay } = useSelector((state: RootState) => state.quiz);
  const valid = useSelector((state: RootState) => state.quiz.musicValid[myIndex]);

  const { isOpen } = useSelector((state: RootState) => state.dialog);

  const updateAudioStatus = (): void => {
    const isPlaying: boolean = myIndex === currentPlay;
    dispatch(updateCurrentPlay(isPlaying ? null : myIndex));
  };
  // dispatch(updateMusicValid(myIndex));
  useEffect(() => {
    const audio = document.getElementById('audio') as HTMLAudioElement;
    const initAudio = () => {
      dispatch(updateLoadedmetadata(myIndex));
      if (currentPlay === null) dispatch(updateMusicValid(myIndex));
    };
    audio.addEventListener('loadedmetadata', initAudio, false);

    // step
    if (step === 4) dispatch(updateMusicValid(myIndex));

    // play
    const actPlay = () => {
      audio.play();
      dispatch(updateCountdown(false));
    };

    // stop
    const actStop = () => {
      audio.pause();
      audio.currentTime = 0;
      // ゲームプレイ中フェーズ 且つ 遊び方ダイアログが開いていない
      if (step === 4 && !isOpen) dispatch(updateCountdown(true));
      dispatch(updateCurrentPlay(null));
    };

    audio.addEventListener('ended', actStop, false);

    // 遊び方ダイアログが開いている、または 他の曲の再生が始まったときの振る舞い
    const isStop: boolean = isOpen || (currentPlay !== myIndex && audio.currentTime > 0);
    if (isStop) actStop();
    else if (currentPlay === myIndex) actPlay();

    // 重複防止
    return () => {
      audio.removeEventListener('loadedmetadata', initAudio);
      audio.removeEventListener('ended', actStop);
    };
  }, [isOpen, step, currentPlay, dispatch]);

  return (
    <div className={style.music}>
      <audio id="audio" src={src}></audio>
      <h2 className={style.headline}>
        <img src={title} alt="課題曲" />
      </h2>
      <p className={style.name}>{props.music}</p>
      <button
        className={style.button}
        onClick={() => updateAudioStatus()}
        disabled={!valid || step > 4}
      >
        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 37 37">
          <path
            fill={!valid || step > 4 ? '#ffffff99' : '#FFFCAD'}
            d="M26.4,18.3c0.5,0.3,0.6,0.9,0.4,1.4c-0.1,0.2-0.2,0.3-0.4,0.4L14,27.2c-0.5,0.3-1.1,0.1-1.4-0.4
      c-0.1-0.2-0.1-0.3-0.1-0.5V12c0-0.6,0.4-1,1-1c0.2,0,0.3,0,0.5,0.1L26.4,18.3z"
          />
        </svg>
        <span>
          <svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 58 25">
            <path
              fill="#FFFFFF"
              d="M56.2,15.1c-1.1-0.3-2.2-0.8-3.4-1.3c-0.7-0.3-1.4-0.6-2.2-0.8c0-0.1,0-0.1,0-0.2c0.2-0.1,0.3-0.3,0.4-0.5
	l0.1-0.1v0c0.4-0.6,0.5-1.3,0.3-2c-0.2-0.7-0.6-1.3-1.2-1.6l-0.4-0.2c0-0.1,0-0.3-0.1-0.4c-0.2-0.7-0.6-1.3-1.2-1.7l-0.4-0.2
	c-0.4-0.2-0.9-0.3-1.3-0.3c-0.2,0-0.4,0-0.6,0.1L46,5.9l-1.2-0.8c-0.4-0.3-0.9-0.5-1.5-0.5c-0.2,0-0.3,0-0.5,0
	c-0.7,0.1-1.3,0.5-1.7,1.1L41,5.9c0,0.1,0,0.2-0.1,0.3c-0.2,0.1-0.5,0.2-0.7,0.4l0.4-2.2c0.2-1.4-0.7-2.8-2.2-3l-8-1.3
	c-0.1,0-0.3,0-0.4,0c-1.3,0-2.4,0.9-2.6,2.2l-0.8,5.1l-0.8-3.7c-0.2-0.7-0.6-1.2-1.1-1.6c-0.4-0.3-0.9-0.4-1.4-0.4v0
	c-0.2,0-0.4,0-0.6,0l-0.7,0.1C21.3,1.9,20.5,2.3,20,3l-1.4,1.8c-0.4,0.5-0.6,1-0.6,1.6c0,0.1,0,0.2,0,0.3c-0.4-0.1-0.7-0.2-1.1-0.3
	c-0.1-0.2-0.1-0.3-0.2-0.5l-0.2-0.3c-0.4-0.6-1-1-1.7-1.1c-0.2,0-0.3-0.1-0.5-0.1c-0.5,0-1,0.1-1.4,0.4c-1,0.6-2.1,1.2-3.2,1.8
	c-0.6,0.4-1,0.9-1.2,1.5c-0.2,0.7-0.2,1.4,0.1,2l0.1,0.3C8.8,10.8,9,11,9.2,11.2c-0.3,0.4-0.4,0.9-0.4,1.4c0,0.4,0.1,0.8,0.3,1.2
	C9,13.9,8.9,13.9,8.8,14c-0.2-0.5-0.5-1-1-1.3l-0.2-0.1c-0.4-0.3-0.9-0.5-1.5-0.5c-0.2,0-0.3,0-0.5,0C5,12.2,4.4,12.6,4,13.2
	l-0.9,1.2H2.8c-0.7,0.1-1.3,0.4-1.8,1l-0.3,0.4c-0.4,0.5-0.6,1.2-0.5,1.9c0,0.2,0.1,0.4,0.2,0.6C0,18.9-0.1,19.6,0,20.2h0
	c0.1,0.7,0.5,1.3,1,1.7L1.3,22c0.2,0.2,0.5,0.3,0.8,0.4c0.2,0.5,0.5,0.9,0.9,1.2l0.2,0.1c0.4,0.3,0.9,0.5,1.5,0.5
	c0.2,0,0.4,0,0.5-0.1c0.6-0.1,1.1-0.4,1.4-0.8C7.4,23.7,8.2,24,9,24c0.3,0,0.5,0,0.8,0c1.3-0.4,2.4-1.3,3.1-2.5
	c0.8-1.1,1.3-2.4,1.4-3.7c0-0.1,0-0.1,0-0.2c0.3,0.1,0.5,0.2,0.8,0.2h0.2c0.6,0,1.1-0.2,1.6-0.6c1.5-1.1,2.6-2.5,3.4-4.1
	c0.5-1.1,0.5-2.4,0.1-3.5c0,0,0.1,0,0.1,0h0.1c0.2,0,0.3,0,0.5,0l0.4,1.7c0.2,0.7,0.6,1.2,1.1,1.6c0.4,0.3,0.9,0.4,1.4,0.4
	c0.2,0,0.4,0,0.6-0.1l0.7-0.2c0.5-0.1,1-0.4,1.3-0.7c0,0,0,0,0,0c0.4,0.6,1,0.9,1.7,1l0.6,0.1c0.1,0,0.3,0,0.4,0
	c0.4,0,0.8-0.1,1.2-0.3l3.9,0.6c0.4,0.4,0.8,0.7,1.4,0.8l0.6,0.1c0.1,0,0.3,0,0.4,0v0c0.5,0,1-0.2,1.4-0.4c0.7,1.2,1.7,2.2,2.8,2.9
	c0.6,0.4,1.3,0.8,2,1.2c0.4,0.2,0.8,0.3,1.2,0.3c0.3,0,0.6,0,0.8-0.1c0.7-0.2,1.2-0.7,1.5-1.4c0,0.3,0,0.6,0,0.9
	c0,1.5,0,2.9-0.1,4.1c0,0.7,0.2,1.3,0.6,1.9c0.4,0.5,1,0.9,1.7,0.9l0.5,0.1h0.3c0.6,0,1.1-0.2,1.6-0.6c0.6-0.4,0.9-1.1,1-1.8
	c0.1-1,0.1-2,0.1-3c0.7,0.3,1.5,0.6,2.2,0.9c0.3,0.1,0.6,0.1,0.9,0.1c0.4,0,0.7-0.1,1.1-0.2c0.6-0.3,1.1-0.8,1.4-1.5l0.1-0.4
	C58.2,17.1,57.5,15.6,56.2,15.1z"
            />
            <path
              fill={!valid || step > 4 ? '#A0A0A0' : '#127FC7'}
              d="M11.2,15.9c-0.2-0.1-0.5-0.3-0.7-0.4c-0.1-0.1-0.3-0.1-0.5,0c-0.2,0.1-0.3,0.2-0.3,0.3l-0.1,0.4
	c-0.1,0.1-0.1,0.3,0,0.5c0.1,0.1,0.2,0.3,0.3,0.3l0.3,0.2c0.3,0.2,0.5,0.5,0.5,0.8c-0.1,0.5-0.4,1-0.7,1.4c-0.4,0.5-0.7,0.8-0.9,0.9
	c-0.4,0-0.8-0.2-1.1-0.4c-0.1-0.1-0.4-0.3-0.9-0.6v-0.1l1.3-1.9c0.2-0.3,0.1-0.7-0.2-0.9l-0.2-0.1c-0.3-0.2-0.6-0.1-0.8,0.2L6,18.2
	H5.9c0,0,0,0,0,0c0,0-0.1,0-0.1,0l-0.7-0.5c0,0,0-0.1,0-0.1l1.7-2.4c0.2-0.3,0.1-0.6-0.1-0.8l-0.2-0.1c-0.3-0.2-0.6-0.1-0.8,0.2
	L4,16.7c0,0,0,0,0,0c0,0-0.1,0-0.1,0l-0.2-0.1c-0.1,0-0.1-0.1-0.2-0.1c-0.3-0.2-0.6-0.1-0.8,0.1L2.4,17c-0.1,0.1-0.1,0.3-0.1,0.4
	c0,0.2,0.1,0.3,0.2,0.4L3,18.1c0,0,0,0,0,0c0,0,0,0.1,0,0.1l-0.8,1.2C2,19.7,2,20.1,2.3,20.3l0.2,0.1c0.3,0.2,0.6,0.1,0.8-0.2
	l0.8-1.1l0,0c0,0,0.1,0,0.1,0l0.7,0.5c0,0,0,0.1,0,0.1L4,21.1c-0.2,0.3-0.1,0.6,0.2,0.8l0.2,0.1c0.3,0.2,0.7,0.1,0.9-0.2L6,20.6
	c0,0,0.1,0,0.1,0L7,21.2c0.6,0.5,1.5,0.8,2.3,0.7c0.9-0.3,1.6-0.9,2-1.7c0.6-0.7,0.9-1.6,1-2.6C12.3,16.9,11.9,16.2,11.2,15.9z
	 M10.4,9.6c0.1,0.1,0.2,0.3,0.3,0.3c0.1,0.1,0.3,0.1,0.5,0c1.3-0.7,2.5-1.3,3.6-2c0,0,0,0,0,0c0.3-0.2,0.4-0.5,0.2-0.8l-0.2-0.3
	c-0.1-0.1-0.2-0.2-0.4-0.2c-0.2,0-0.3,0-0.5,0.1c-1.1,0.7-2.2,1.3-3.4,1.9c-0.1,0.1-0.2,0.2-0.3,0.4c-0.1,0.1-0.1,0.3,0,0.5
	L10.4,9.6z M16.7,8.5c-0.9,0-1.7,0.3-2.4,0.8c-1.2,0.8-2.3,1.8-3.3,2.8c-0.1,0.1-0.2,0.3-0.2,0.4c0,0.2,0.1,0.3,0.2,0.4l0.2,0.2
	c0.1,0.1,0.2,0.2,0.4,0.2c0.2,0,0.3-0.1,0.4-0.2c0.9-1,1.9-1.8,3-2.6c0.4-0.3,0.8-0.4,1.2-0.5c0.3,0,0.6,0.1,0.7,0.4
	c0.6,0.9-0.1,2.3-2.2,4c-0.1,0.1-0.2,0.2-0.2,0.4c0,0.2,0,0.3,0.1,0.4l0.2,0.2c0.1,0.1,0.3,0.2,0.4,0.2c0.2,0,0.3,0,0.5-0.1
	c1.2-0.8,2.1-2,2.8-3.3c0.4-0.9,0.3-2-0.2-2.8C18,8.9,17.3,8.5,16.7,8.5z M24,3.9c0-0.2-0.1-0.3-0.3-0.4c-0.1-0.1-0.3-0.1-0.5-0.1
	l-0.7,0.1c-0.4,0.1-0.8,0.3-1,0.6L20.1,6C20,6.2,20,6.3,20,6.5c0,0.2,0,0.3,0.1,0.5l0.2,0.4c0.1,0.1,0.3,0.2,0.4,0.1
	c0.1,0,0.3-0.1,0.4-0.2l1.3-1.6l1.1,5c0,0.2,0.1,0.3,0.3,0.4c0.1,0.1,0.3,0.1,0.5,0.1l0.7-0.1c0.2,0,0.3-0.1,0.4-0.3
	c0.1-0.1,0.1-0.3,0.1-0.5L24,3.9z M35.4,5.1l-3.2-0.5c-0.3,0-0.6,0.2-0.7,0.5L31,8.3c0,0.3,0.2,0.6,0.5,0.7l0.8,0.1l2.3,0.4
	c0.3,0,0.6-0.2,0.7-0.5l0.5-3.2C36,5.5,35.7,5.2,35.4,5.1z M34.1,8.1C34.1,8.1,34,8.2,34.1,8.1l-1.5-0.2c-0.1,0-0.1,0-0.1-0.1
	l0.3-1.7l0.1,0C32.9,6,32.9,6,33,6l1.3,0.2c0,0,0.1,0.1,0.1,0.1L34.1,8.1z M38.2,3.3l-8-1.3c-0.2,0-0.3,0-0.5,0.1
	c-0.1,0.1-0.2,0.2-0.2,0.4l-1.3,8.1c0,0.3,0.2,0.6,0.5,0.7l0.6,0c0.1,0,0.2,0,0.3-0.1c0.1-0.1,0.2-0.2,0.2-0.3c0,0,0,0,0.1,0l5.8,1
	c0,0,0,0,0,0.1c0,0.1,0,0.2,0.1,0.3c0.1,0.1,0.2,0.2,0.3,0.2l0.6,0.1c0.3,0,0.6-0.2,0.7-0.5L38.7,4c0-0.2,0-0.3-0.1-0.4
	C38.5,3.4,38.3,3.3,38.2,3.3z M35.9,10.7C35.9,10.7,35.9,10.7,35.9,10.7L30,9.7c0,0-0.1-0.1,0-0.1l1-6l0,0c0,0,0,0,0,0
	c0,0,0.1-0.1,0.1-0.1l5.8,1c0.1,0,0.1,0,0.1,0.1L35.9,10.7z M48.2,12.2l-1.5-0.9c0,0,0-0.1,0-0.1c0-0.1,0.1-0.3,0.2-0.5H47l0.7,0.5
	l0.7,0.4c0.3,0.2,0.7,0.1,0.8-0.2l0.1-0.1c0.1-0.1,0.1-0.3,0.1-0.5c0-0.2-0.1-0.3-0.3-0.4l-1.6-1c0,0,0-0.1,0-0.1l0.1-0.3
	c0.1-0.1,0.1-0.3,0.1-0.5c0-0.2-0.1-0.3-0.3-0.4l-0.4-0.2c-0.1-0.1-0.3-0.1-0.5-0.1c-0.2,0-0.3,0.1-0.4,0.3l-0.1,0.1
	c0,0-0.1,0.1-0.1,0.1h-0.1c-1.1-0.7-1.9-1.3-2.5-1.6c-0.1-0.1-0.3-0.1-0.5-0.1c-0.2,0-0.3,0.1-0.4,0.2L42.7,7c0,0,0,0,0,0
	c-0.2,0.3-0.1,0.7,0.2,0.8c0.8,0.5,1.7,1.1,2.7,1.8v0.1c-0.1,0.2-0.2,0.5-0.2,0.5h-0.1c-1.4-0.9-2.5-1.6-3.2-2.1
	c-0.3-0.2-0.6-0.1-0.8,0.2l-0.1,0.1C41,8.7,41,9,41.3,9.2c1.6,1.1,2.8,1.9,3.6,2.4c0,0,0.1,0.1,0.1,0.1c-0.1,0.3-0.1,0.5-0.1,0.6
	c-0.3-0.4-0.7-0.7-1.1-1c-0.7-0.5-1.5-0.8-2.3-0.9c-0.6,0-1.2,0.2-1.5,0.7c-0.9,1.2-0.2,2.7,2.1,4.2c0.6,0.4,1.2,0.8,1.8,1.1
	c0.1,0.1,0.3,0.1,0.5,0c0.2,0,0.3-0.2,0.4-0.3l0.1-0.2c0,0,0,0,0,0c0.2-0.3,0-0.7-0.3-0.8c-0.6-0.3-1.1-0.6-1.7-1
	c-0.5-0.3-1-0.7-1.4-1.2c-0.2-0.2-0.2-0.6-0.1-0.9c0.2-0.4,0.8-0.3,1.6,0.3c0.4,0.3,0.8,0.7,1.1,1.1c0.2,0.3,0.6,0.5,0.9,0.6
	l0.7,0.1c0.2,0,0.3,0,0.4-0.1c0.1-0.1,0.2-0.2,0.2-0.4c0-0.4,0.1-0.8,0.1-1.1c0,0,0.1,0,0.1,0L47,13l0.5,0.3
	c0.1,0.1,0.3,0.1,0.5,0.1c0.2,0,0.3-0.1,0.4-0.3l0.1-0.1C48.6,12.7,48.5,12.3,48.2,12.2z M56,17.4c-0.1-0.1-0.2-0.3-0.4-0.3
	c-1.1-0.4-2.3-0.9-3.5-1.4c-0.6-0.3-1.2-0.5-1.9-0.7c-0.4,0-0.7,0.1-1,0.3c-0.3,0.2-0.5,0.4-0.6,0.8c-0.1,0.7-0.1,1.3-0.1,2
	c0,1.5,0,3-0.1,4.3c0,0.2,0,0.3,0.2,0.5c0.1,0.1,0.3,0.2,0.4,0.2l0.5,0c0.2,0,0.3,0,0.4-0.1c0.1-0.1,0.2-0.2,0.2-0.4
	c0.1-1.5,0.1-2.9,0.1-4.4c0-0.3,0-0.6,0-0.9c0-0.1,0-0.1,0-0.2c0.1-0.2,0.3-0.2,0.5-0.1c0.3,0.1,0.6,0.2,0.9,0.4
	c1.1,0.5,2.2,0.9,3.5,1.4c0.1,0.1,0.3,0.1,0.5,0c0.1-0.1,0.3-0.2,0.3-0.3l0.2-0.5C56,17.7,56,17.5,56,17.4z"
            />
          </svg>
        </span>
      </button>
    </div>
  );
};
