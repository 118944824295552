import { FC } from 'react';

// assets
import style from '../assets/style/components/series.module.scss';
import title1 from '../assets/image/play/txt_title1.svg';
import title2 from '../assets/image/play/txt_title2.svg';
import title3 from '../assets/image/play/txt_title3.svg';
import title4 from '../assets/image/play/txt_title4.svg';
import title5 from '../assets/image/play/txt_title5.svg';
// function component
type Props = {
  level: 1 | 2 | 3;
  challenge: 0 | 1 | 2;
};

export const Series: FC<Props> = (props: Props) => {
  const titleImages = [title1, title2, title3, title4, title5];
  const current = props.challenge ? props.challenge - 1 : props.level + 1;

  return <img className={style.title} src={titleImages[current]} alt="" />;
};
