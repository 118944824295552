import { createSlice } from '@reduxjs/toolkit';

type State = {
  isChangeUrl: boolean;
};

const initialState: State = {
  isChangeUrl: false,
};

const newBadge = createSlice({
  name: 'newBadge',

  initialState,

  reducers: {
    updateAnimation: (state, action) => {
      state.isChangeUrl = action.payload;
      const elements = document.getElementsByClassName('new');
      if (action.payload === true) {
        // start
        for (let i = 0; i < elements.length; i++) {
          elements[i].classList.add('isChangeUrl');
        }
      } else {
        // end
        for (let i = 0; i < elements.length; i++) {
          elements[i].classList.remove('isChangeUrl');
        }
      }
    },
  },
});

// Action Creators
export const { updateAnimation } = newBadge.actions;

// Reducer
export default newBadge.reducer;
