/* eslint @typescript-eslint/ban-ts-comment: 0 */
import React, { FC, useState } from 'react';

// animation
import { useSpring, animated } from '@react-spring/web';

// function component
type Props = {
  onClick(event?: React.MouseEvent): void;
  children?: React.ReactNode;
  delay?: number;
  className?: string;
  disabled?: boolean;
  type?: 'reset' | 'button' | 'submit';
};
export const Bounce: FC<Props> = (props: Props) => {
  // animation
  const [toggle, toggleSet] = useState<number>(0);
  const config = { mass: 1, tension: 400, friction: 20 };
  const spring = useSpring({ toggle, config });

  const handleAction = (e: React.MouseEvent): void => {
    toggleSet(toggle ? 0 : 1);
    setTimeout(() => {
      props.onClick(e);
    }, props.delay ?? 300);
  };

  return (
    <>
      <animated.button
        className={props.className}
        style={{
          transform: spring.toggle
            .interpolate({ range: [0, 0.5, 1], output: [1, 1.05, 1] })
            .interpolate(s => `scale(${s}) `),
        }}
        onClick={handleAction}
        disabled={props.disabled}
        type={props.type}
      >
        {/* {myChild} */}
        {props.children}
      </animated.button>
    </>
  );
};
