/* eslint @typescript-eslint/ban-ts-comment: 0 */
import { FC, useRef, useEffect, useState } from 'react';

// redux
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../store';
import { updateAnswer, updateCurrentPlay } from '../store/quiz';

// lodash
import { clamp } from 'lodash-es';

// animate
import { useGesture } from 'react-use-gesture';
import { useSprings, animated } from '@react-spring/web';

// assets
import style from '../assets/style/components/order.module.scss';

// component
import { Peace } from './piece';
import { Note } from './note';

// function component
type Props = {
  storage?: string;
  parts?: {
    label: string;
    color: string;
    audio: number;
  }[];
};

export const Order: FC<Props> = (props: Props) => {
  const dispatch = useDispatch();
  // const [dragging, setDragging] = useState<boolean>(false);
  const [currentItem, setCurrentItem] = useState<number>(0);
  const [targetItem, setTargetItem] = useState<number>(0);

  const step = useSelector((state: RootState) => state.quiz.step);

  const audio = props.parts?.map(item => item.audio) as number[];
  // const colors = props.parts?.map(item => item.color) as string[];
  const colors = ['red', 'yellow', 'green', 'blue', 'purple'] as string[];
  const items = props.parts?.map(item => item.label) as string[];
  const order = useRef(items.map((_, index) => index));

  const width = window.innerWidth < 375 ? 64 : 67 + 5;

  /* eslint-disable prettier/prettier */
  // Returns fitting styles for dragged/idle items
  const fn =
    (
      order: number[],
      down?: boolean,
      originalIndex?: number,
      curIndex?: number,
      x?: number,
      y?: number
    ) =>
    (index: number) =>
      down && index === originalIndex
        ? {
            x: curIndex! * width + x!,
            y: clamp(y!, -96, 0),
            scale: 1.2,
            zIndex: '1',
            immediate: (n: string) => n === 'x' || n === 'zIndex',
          }
        : {
            x: order.indexOf(index) * width,
            y: 0,
            scale: 1,
            zIndex: '0',
            immediate: false,
          };
  /* eslint-enable prettier/prettier */

  const [springs, setSprings] = useSprings(order.current.length, fn(order.current) as never);
  const trade = (all: number[], from: number, to: number): number[] => {
    const result: number[] = [];
    all.forEach((item: number, index: number): void => {
      if (index === from) result.push(all[to]);
      else if (index === to) result.push(all[from]);
      else result.push(all[index]);
    });
    return result;
  };

  // ユーザ操作のイベントを登録
  const bind = useGesture({
    // ドラッグ開始
    onDragStart: ({ args: [originalIndex] }) => {
      if (step === 4) {
        const curIndex = order.current.indexOf(originalIndex);
        setCurrentItem(curIndex);
        // setDragging(false); // リセットしてから
        // setDragging(true); // 再設定
        dispatch(updateCurrentPlay(null));
      }
    },
    // ドラッグ中
    onDrag: ({ args: [originalIndex], down, movement: [x, y] }) => {
      if (step === 4) {
        const curIndex = order.current.indexOf(originalIndex);
        // @ts-ignore
        if (down) setSprings(fn(order.current, down, originalIndex, curIndex, x, y));

        // 入れ替え対象
        const curRow = clamp(Math.round((curIndex * width + x) / width), 0, items.length - 1);
        setTargetItem(curRow);
      }
    },
    // ドラッグ終了
    onDragEnd: ({ args: [originalIndex], down, movement: [x, y] }) => {
      if (step === 4) {
        const curIndex = order.current.indexOf(currentItem);

        const newOrder = trade(order.current, currentItem, targetItem);
        order.current = newOrder;

        // @ts-ignore
        setSprings(fn(newOrder, down, originalIndex, curIndex, x, y));
        // setDragging(false);

        dispatch(updateAnswer(order.current)); // 現在の回答内容
        // console.log('dragging:' + dragging);
        // console.log('end:' + newOrder);
        // console.log('end:' + currentItem + '->' + targetItem);
      }
    },
  });

  useEffect(() => {
    dispatch(updateAnswer(order.current));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={style.order}>
      <div className={style.list}>
        {/* @ts-ignore */}
        {springs.map(({ zIndex, x, y, scale }, i) => (
          <animated.div
            {...bind(i)}
            key={i}
            style={{
              zIndex,
              // @ts-ignore
              x,
              y,
              scale,
            }}
            className={style.item}
          >
            <Note storage={props.storage} color={colors[i]} text={items[i]} index={audio[i]} />
          </animated.div>
        ))}
      </div>
      <div className={style.piece}>
        {props.parts?.map((item, i) => (
          <Peace
            key={i}
            storage={props.storage}
            color={colors[order.current[i]]}
            index={audio[order.current[i]]}
          />
        ))}
      </div>
    </div>
  );
};
