import { createSlice } from '@reduxjs/toolkit';
import { Question } from '../@types/question';
import listJson from '../data/question.json';

type State = {
  list: Question[];
};

const initialState: State = {
  list: (listJson as Question[]) ?? undefined,
};

const question = createSlice({
  name: 'question',

  initialState,

  reducers: {
    addQuestion: (state, action) => {
      state.list.push(action.payload);
    },
  },
});

// Action Creators
export const { addQuestion } = question.actions;

// Reducer
export default question.reducer;
