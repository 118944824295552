/* eslint @typescript-eslint/ban-ts-comment: 0 */
import { FC, useEffect, useState, useMemo } from 'react';

// router
import { useHistory, useParams } from 'react-router-dom';

// redux
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../store';
import {
  judgeRelease,
  updateStep,
  updateCountdown,
  updateCurrentPlay,
  updateResult,
} from '../store/quiz';

// lodash
import { isEqual } from 'lodash-es';

// assets
import style from '../assets/style/pages/play.module.scss';
import judging from '../assets/image/play/txt_judging.svg';

// type
import { Question } from '../@types/question';

// component
import { Series } from '../components/series';
import { Music } from '../components/music';
import { Timer } from '../components/timer';
import { Order } from '../components/order';
import { GameStep } from '../components/gameStep';
import { GuideButton } from '../components/guideButton';
import { Character } from '../components/character';

// function component
interface ParamTypes {
  id: string;
}

const DraggableList: FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  // 解禁日時以降かの判定用
  const { isTimeOver } = useSelector((state: RootState) => state.application);

  // クイズの状態
  const { step, isFinish, musicValid, currentPlay, time, answer, result } = useSelector(
    (state: RootState) => state.quiz
  );

  // ダイアログの状態
  const { isOpen } = useSelector((state: RootState) => state.dialog);

  // get Question
  const { id } = useParams<ParamTypes>();
  const myId = id;
  const list = useSelector((state: RootState) => state.question.list ?? []) as Question[];
  const question = useMemo(() => {
    return list.find(item => item.quizId === myId);
  }, [list, myId]);

  // mp3の保存場所
  const storage: string = useMemo(() => {
    return (
      (process.env.REACT_APP_ASSET_ENDPOINT as string) +
      myId.replace(/^([0-9]{4})-([0-9]{2})-([0-9]{2})-([0-9])$/g, '$1$2$3/music$4/')
    );
  }, [myId]);

  // ブラウザバック対応
  // リザルトから ... 問題一覧へリダイレクト
  if (step === 9 && result.quizId !== '') {
    history.push(`/`);
  }
  // チャレンジ問題のプレイ可能判定
  useEffect(() => {
    if (question?.release) {
      const judge = async (params: { release: string; quizId: string }) => {
        const result = await dispatch(judgeRelease({ period: params.release }));
        if (result) {
          // @ts-ignore
          if (!result.payload.isReleased || isTimeOver === true) history.push(`/`);
        }
      };

      if (question) judge({ release: question.release, quizId: myId });
    }
  }, [dispatch, history, isTimeOver, myId, question]);

  // 正誤判定
  const actJudge = () => {
    const audioList = question?.parts.map(item => item.audio) as number[];
    const answerAudio = answer.map(index => audioList[+(index ?? '-1')]);
    const correct = isEqual(answerAudio, [0, 1, 2, 3, 4]);
    const labelList = question?.parts.map(item => item.label) as string[];
    const myOrder = answer.map(index => labelList[+(index ?? '-1')]);
    const correctOrder = [0, 1, 2, 3, 4].map(ind => {
      const target = question?.parts.find(item => item.audio === ind);
      return target?.label;
    }) as string[];

    dispatch(
      updateResult({
        quizId: question?.quizId,
        isCorrect: correct,
        myOrder,
        correctOrder,
        time,
      })
    );
  };

  const [count, setCount] = useState<number>(0);

  // 回答の再生〜遷移
  useMemo(() => {
    const allValid = isEqual(musicValid, new Array<boolean>(6).fill(true));
    const audioList = question?.parts.map(item => item.audio) as number[];
    const answerAudio = answer.map(index => audioList[+(index ?? '-1')]);
    if (step === 7 && allValid) {
      if (count < answer.length) {
        dispatch(updateCurrentPlay(answerAudio[count]));
        setCount(count + 1);
      } else if (count === answer.length) {
        dispatch(updateStep(8));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [step, musicValid]);

  // 答え合わせ
  useMemo(() => {
    if (isFinish) {
      // 再生途中のものがあれば停止
      if (currentPlay !== null) dispatch(updateCurrentPlay(null));

      // 時計をとめる
      dispatch(updateCountdown(false));

      // 答え合わせ
      actJudge();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFinish]);

  // リザルト
  useMemo(() => {
    if (step === 8) {
      // 自動再生を終える
      dispatch(updateCurrentPlay(null));
      // N秒後に結果へ遷移
      setTimeout(() => {
        history.push(`/result/${id}`);
        dispatch(updateStep(9));
      }, 3000);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [step]);

  useMemo(() => {
    // ゲーム中に遊び方ダイアログを開いたらカウントストップ
    if (step === 4) dispatch(updateCountdown(!isOpen));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [step, isOpen]);

  const height = window.innerHeight;

  // Google Analytics
  useEffect(() => {
    document.title = `${question?.music}`;
    // @ts-ignore
    window.gtagPageView(history.location.pathname);
  }, [question?.music, history]);

  const challenge = (!question?.release ? 0 : myId.match(/1$/) ? 1 : 2) as 0 | 1 | 2;

  return (
    // @ts-ignore
    <article className={`l-page ${style.play}`} style={{ height: height }} data-step={step}>
      <header className={style.header}>
        <h1>
          <Series level={question?.level ?? 1} challenge={challenge} />
        </h1>
        <GuideButton />
      </header>
      <Music storage={storage} music={question?.music} />
      <Timer />
      <Character type={1} wrapClass={style.charaRedPosition} imgClass={style.charaRed} />
      <Order storage={storage} parts={question?.parts} />
      <footer className={style.footer}>
        <button
          className={style.finish}
          onClick={e => {
            e.currentTarget.setAttribute('aria-busy', 'true');
            dispatch(updateStep(5));
          }}
          disabled={step !== 4}
        ></button>
        <img src={judging} className={style.judging} alt="" />
      </footer>
      <GameStep />
    </article>
  );
};

export default DraggableList;
