/* eslint @typescript-eslint/ban-ts-comment: 0 */
import { FC, useState, useEffect } from 'react';

// redux
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../store';
import { updateStep, updateTime } from '../store/quiz';

// assets
import style from '../assets/style/components/timer.module.scss';

// function component
export const Timer: FC = () => {
  const dispatch = useDispatch();

  const { timeLimit, time, isCountdown } = useSelector((state: RootState) => state.quiz);
  const [currentTime, setTime] = useState<number>(100 - time);

  const [pathLength, setPathLength] = useState<number>(0);
  const [dashoffset, setDashoffset] = useState<number>(93.6404);
  const [classList, setClassList] = useState<string[]>([style.clock]);

  useEffect(() => {
    // eslint-disable-next-line prettier/prettier
    const clock = document.getElementById('clock') as unknown as SVGPathElement;
    setPathLength(clock.getTotalLength());
    setDashoffset(pathLength);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const progress = time / timeLimit;
    const offset = pathLength * (1 - progress);
    setDashoffset(offset);

    setTime(timeLimit - time);
    const t = time + 1;
    const tick = setInterval(() => {
      if (!isCountdown || t > timeLimit) {
        clearInterval(tick);
        if (t > timeLimit) dispatch(updateStep(6));
      } else {
        dispatch(updateTime(t));
        if (time >= 74) setClassList([style.clock, style.dangerous]);
      }
    }, 1000);

    return () => clearInterval(tick);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [time, isCountdown, dashoffset]);

  return (
    <div className={classList.join(' ')}>
      <svg className={style.circle} viewBox=" 0 0 60 60">
        <circle
          id="clock"
          style={{ strokeDasharray: `${pathLength} ${pathLength}`, strokeDashoffset: dashoffset }}
          className={style.gauge}
          cx="30"
          cy="30"
          r="15"
        />
        <circle className={style.scale_s} cx="29" cy="6" r="2.5" />
        <circle className={style.scale} cx="30" cy="5" r="2.5" />
        <circle className={style.scale_s} cx="29" cy="56" r="2.5" />
        <circle className={style.scale} cx="30" cy="55" r="2.5" />
        <circle className={style.scale_s} cx="4" cy="31" r="2.5" />
        <circle className={style.scale} cx="5" cy="30" r="2.5" />
        <circle className={style.scale_s} cx="54" cy="31" r="2.5" />
        <circle className={style.scale} cx="55" cy="30" r="2.5" />
      </svg>
      <span className={style.count}>{currentTime}</span>
    </div>
  );
};
