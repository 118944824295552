import { FC, useEffect } from 'react';

// redux
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../store';
import {
  updateMusicValid,
  setDisabled,
  clearDisabled,
  updateCurrentPlay,
  updateLoadedmetadata,
} from '../store/quiz';

// assets
import style from '../assets/style/components/piece.module.scss';
import { Bounce } from './bounce';

// function component
type Props = {
  storage?: string;
  color: string;
  index: number;
};

export const Peace: FC<Props> = (props: Props) => {
  const dispatch = useDispatch();

  const { step, currentPlay } = useSelector((state: RootState) => state.quiz);
  const valid = useSelector((state: RootState) => state.quiz.musicValid[props.index]);

  const src = `${props.storage}piece${props.index}.mp3`;

  const updateAudioStatus = (): void => {
    const isPlaying: boolean = props.index === currentPlay;
    dispatch(updateCurrentPlay(isPlaying ? null : props.index));
  };

  useEffect(() => {
    const audio = document.getElementById(`piece${props.index}`) as HTMLAudioElement;
    const initAudio = () => {
      dispatch(updateLoadedmetadata(props.index));
      if (currentPlay === null) dispatch(updateMusicValid(props.index));
    };
    audio.addEventListener('loadedmetadata', initAudio, false);

    // play
    const actPlay = () => {
      audio.play();

      const playing = new Array<boolean>(6).fill(false);
      playing[props.index] = true;

      dispatch(setDisabled(props.index));
    };

    // stop
    const actStop = () => {
      audio.pause();
      audio.currentTime = 0;
      dispatch(clearDisabled());
      // step 4 ... ゲームプレイ中の場合
      // step 7 ... 答え合わせの自動再生ではnullをはさまない
      // step 8 ... 答え合わせの自動再生が終わった
      if (step === 4) dispatch(updateCurrentPlay(null));
    };

    audio.addEventListener('ended', actStop, false);

    // 値が変わったときの振る舞い
    const isStop: boolean = currentPlay !== props.index && audio.currentTime > 0;
    if (isStop) actStop();
    if (currentPlay === props.index) actPlay();

    // 重複防止
    return () => {
      audio.removeEventListener('loadedmetadata', initAudio);
      audio.removeEventListener('ended', actStop);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPlay]);

  return (
    <div>
      <audio id={`piece${props.index}`} src={src}></audio>
      <Bounce
        className={[style[`button-${props.color}`], style.button].join(' ')}
        onClick={() => updateAudioStatus()}
        disabled={!valid}
      >
        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 45 45">
          <path
            d="M28.2,23c0-2.2-1.2-4.1-3.2-5.1v10.1C27,27.1,28.3,25.2,28.2,23z M11,19.2v7.5h5.1l6.4,6.3V12.9l-6.4,6.3H11z
	 M25.1,12v2.6c2.9,0.8,5.2,3.1,6,6c1.3,4.6-1.4,9.5-6,10.8V34c4.4-0.9,7.8-4.3,8.7-8.7C35,19.2,31.2,13.3,25.1,12z"
          />
        </svg>
      </Bounce>
    </div>
  );
};
