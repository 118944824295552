import { FC } from 'react';

// redux
import { useDispatch } from 'react-redux';
import { updateCondition, updateOpen, updateContent, updateClass } from '../store/dialog';

// assets
import style from '../assets/style/components/guideButton.module.scss';
import ico_guide from '../assets/image/ico_guide.svg';

// component
import { Guide } from '../components/guide';

export const GuideButton: FC = () => {
  const dispatch = useDispatch();

  const openGuide = () => {
    const contents = <Guide></Guide>;
    dispatch(updateCondition('openGuide'));
    dispatch(updateContent(contents));
    dispatch(updateOpen(true));
    dispatch(updateClass([style.guideWrapper]));
  };

  const baseClass = [style.guide];

  return (
    <button className={baseClass.join(' ')} onClick={openGuide}>
      <img src={ico_guide} alt="?" />
      遊び方
    </button>
  );
};
