/* eslint @typescript-eslint/ban-ts-comment: 0 */
import { memo, FC, useEffect, useCallback, useMemo, useRef } from 'react';

// router
import { useHistory } from 'react-router-dom';

// redux
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../store';
import { judgePeriodOver, updateDeadline, updateApply } from '../store/application';
import { getSaveData, confirmError } from '../store/user';
import { allInitial, judgeRelease } from '../store/quiz';
import { updateCondition, updateOpen, updateContent, setCallback } from '../store/dialog';

// キーワードをタップするとクリップボードへコピー
import { CopyToClipboard } from 'react-copy-to-clipboard';

// scroll animation
import { animateScroll } from 'react-scroll';

// プレゼント応募キーワードGAS取得
import applyKeyword from '../data/keyword.json';

// assets
import style from '../assets/style/pages/home.module.scss';
import logo from '../assets/image/home/logo.png';
import txt_lead from '../assets/image/home/txt_lead.svg';
import txt_training from '../assets/image/home/txt_training.svg';
import txt_apply from '../assets/image/home/txt_apply.svg';
import hd_challenge from '../assets/image/home/challenge/txt_title.svg';
import hd_challengesub from '../assets/image/home/challenge/txt_subtitle.png';
import hd_warmup from '../assets/image/home/warmup/txt_title.svg';
import hd_warmupsub from '../assets/image/home/warmup/txt_subtitle.png';
import hd_warmup_link from '../assets/image/home/warmup/txt_link.svg';
import gift from '../assets/image/gift.png';

// component
import { GuideButton } from '../components/guideButton';
import { Character } from '../components/character';
import { Bounce } from '../components/bounce';

// function component
export const Home: FC = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const { deadline, isApply, isTimeOver } = useSelector((state: RootState) => state.application);
  const { userId, isError, isLoading, save } = useSelector((state: RootState) => state.user);
  const list = useSelector((state: RootState) => state.question.list);

  // キーワード
  const ApplicationKeyword = memo(() => {
    // プレゼント応募キーワード
    const envKeyword = process.env.REACT_APP_APPLY_KEYWORD as string;
    const str = envKeyword !== 'false' ? envKeyword : (applyKeyword.value as string);
    const keywordElement = useRef(null);
    const actCopy = useCallback(() => {
      // @ts-ignore
      const target = keywordElement.current as HTMLElement;
      target.setAttribute('data-copied', 'true');
      setTimeout(() => {
        target.removeAttribute('data-copied');
      }, 1000);
    }, [keywordElement]);
    return (
      <CopyToClipboard text={str} onCopy={actCopy}>
        <div ref={keywordElement} className={style.gifKeyword}>
          <dl>
            <dt>キーワード</dt>
            <dd>{str}</dd>
          </dl>
          <small className={style.gifKeywordGuide}>
            タップするとコピーできるよ
            <br />
            応募フォームに入力してね！
          </small>
        </div>
      </CopyToClipboard>
    );
  });

  // プレゼント応募締切
  const periodText = useMemo(() => {
    let result = {
      year: '',
      month: '',
      date: '',
      day: '',
      hour: '',
      minute: '',
    };
    if (deadline) {
      // 日本語の日付表記を取得
      const dateDeadline = new Date(deadline);

      result = {
        year: dateDeadline.getFullYear() + '',
        month: dateDeadline.getMonth() + 1 + '',
        date: dateDeadline.getDate() + '',
        day: ['日', '月', '火', '水', '木', '金', '土'][dateDeadline.getDay()],
        hour: dateDeadline.getHours() + '',
        minute: `00${dateDeadline.getMinutes()}`.slice(-2),
      };
      // 期日内判定
      dispatch(judgePeriodOver({ period: deadline }));
    }
    return result;
  }, [dispatch, deadline]);
  // プレゼント応募締切の日本語表記memo
  const ApplicationPeriod = memo(() => {
    return (
      <>
        {periodText.year}年{periodText.month}月{periodText.date}日({periodText.day})&nbsp;
        {periodText.hour}:{periodText.minute}
      </>
    );
  });

  useEffect(() => {
    // ダイアログへメッセージを設定
    dispatch(
      updateContent(
        <p>
          ごめんなさい！
          <br />
          <span className="u-textAccent">通信に失敗</span>しました
        </p>
      )
    );

    // ダイアログを閉じるときエラー解除
    dispatch(
      setCallback((isOpen: boolean): void => {
        if (!isOpen) dispatch(confirmError());
      })
    );

    // ゲームのプレイ中ステータスをリセット
    dispatch(allInitial());

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // プレゼント応募締切を設定
  useMemo(() => {
    // 環境変数に上書き設定が指定されているか確認
    const overwriteCloseDatetime = process.env.REACT_APP_APPLICATION_DEADLINE as string;
    // チャレンジ問題の出題データからデフォルトを放送日を取得
    const newerQuestion = list.find(item => item.release !== undefined);
    let defaultCloseDatetime = undefined;
    if (newerQuestion) {
      const releaseDatetime = newerQuestion.release
        ? new Date(newerQuestion.quizId.replace(/([0-9]{4}-[0-9]{2}-[0-9]{2}).*$/g, '$1'))
        : undefined;
      if (releaseDatetime) {
        // リリース翌日を取得
        const releaseNextDate = releaseDatetime.setDate(releaseDatetime.getDate() + 1);
        const releaseNextDatetime = new Date(releaseNextDate);
        // リリース翌日の23:59を ISO8601形式の文字列として取得
        defaultCloseDatetime = releaseNextDatetime
          ? releaseNextDatetime.getFullYear() +
            '-' +
            ('0' + (releaseNextDatetime.getMonth() + 1)).slice(-2) +
            '-' +
            ('0' + releaseNextDatetime.getDate()).slice(-2) +
            'T23:59:59+09:00'
          : undefined;
      }
    }
    // 環境変数の上書きが見つからない場合は、放送日翌日の23:59を指定
    dispatch(
      updateDeadline(
        overwriteCloseDatetime !== 'false' ? overwriteCloseDatetime : defaultCloseDatetime
      )
    );
  }, [list, dispatch]);

  // Google Analytics
  useEffect(() => {
    document.title = `メロディシャッフリン`;
    // @ts-ignore
    window.gtagPageView(history.location.pathname);

    // 他のページから遷移してきたときセーブデータをリクエスト
    if (history.location.pathname === '/' && !isLoading && userId !== undefined)
      dispatch(getSaveData({ userId, data: save }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history, userId]);

  useMemo(() => {
    if (isError === true && isLoading === false) {
      dispatch(updateCondition('rejectedLoad'));
      dispatch(updateOpen(true));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isError]);

  const judge = async (params: { release?: string; isCorrect?: boolean; quizId: string }) => {
    const { quizId, isCorrect, release } = params;
    // リリース時刻が設定してあるものは時刻判定
    if (release) {
      const message = (
        <p>
          この問題は出題前のため
          <br />
          まだ挑戦することができません
          <br />
          <span className="u-textAccent">{releaseText(release)}ごろの出題後</span>
          に
          <br />
          再度アクセスしてください
        </p>
      );
      const result = await dispatch(judgeRelease({ period: release }));
      if (result) {
        // @ts-ignore
        if (!result.payload.isReleased) {
          dispatch(updateCondition('invalidRelease'));
          dispatch(updateContent(message));
          dispatch(updateOpen(true));
          // @ts-ignore
        } else if (isCorrect !== undefined) history.push(`/result/${quizId}`);
        // @ts-ignore
        else history.push(`/play/${quizId}`);
      }
    }
    // リリース時刻がないものはそのまま遷移
    // @ts-ignore
    else history.push(`/play/${quizId}`);
  };

  // セーブデータと照合
  let questionList = !save
    ? list
    : list.map(item => {
        const equals = save.find(data => data.quizId === item.quizId);
        return {
          ...item,
          ...equals,
        };
      });
  questionList = questionList.slice(0, 5);
  // プレゼント応募権
  useMemo(() => {
    const list = save?.filter(data => data.isCorrect === true);
    const valid = !list ? false : !list.length ? false : true;
    dispatch(updateApply(valid));
  }, [dispatch, save]);

  const releaseText = (time?: string) => {
    if (time) {
      const hours = new Date(time).getHours();
      const minutes = `00${new Date(time).getMinutes()}`.slice(-2);
      const prefix = (hours: number) => {
        /** TBS番組表
         * @see https://www.tbs.co.jp/tv/nextweek.html
         */
        // 24〜3
        let result = '深夜';
        // 4〜11
        if (hours >= 4 && hours < 11) result = 'あさ';
        // 11〜12
        if (hours >= 11 && hours < 13) result = 'ひる';
        // 13〜18
        if (hours >= 13 && hours < 19) result = 'ごご';
        // 19〜24
        else if (hours >= 19 && hours < 24) result = 'よる';
        return result;
      };
      return `${prefix(hours)} ${hours % 12}:${minutes}`;
    }
  };

  return (
    <div className={`l-page ${style.home}`}>
      <header>
        <Character
          type={0}
          wrapClass={style.charaBlueHeaderPosition}
          imgClass={style.charaBlueHeader}
        />
        <Character
          type={1}
          wrapClass={style.charaRedHeaderPosition}
          imgClass={style.charaRedHeader}
        />
        <h1>
          <img src={logo} alt="メロディシャッフリン" width="210" height="92" />
        </h1>
        <p>
          <img src={txt_lead} alt="100秒以内にメロディを正しく並び替えよう！" />
        </p>
        <div className={style.navi}>
          <GuideButton></GuideButton>
          <button
            className={style.toWarmup}
            onClick={() => {
              animateScroll.scrollToBottom();
            }}
          >
            <img src={txt_training} alt="練習問題" />
          </button>
        </div>
      </header>

      <div className={style.homeBody}>
        <article className={style.gift}>
          <header>
            <small>
              番組放送中に
              <br />
              チャレンジ問題を出題！
            </small>
            <h2>
              <span className="u-textAccent">1問でも正解</span>すれば
              <br />
              プレゼントGETのチャンス！
            </h2>
          </header>
          <div>
            <figure>
              <img src={gift} alt="" width="325" height="123" />
            </figure>
            <p className={style.giftFigcaption}>
              <small className={style.small}>番組オリジナル ワイヤレススピーカーを</small>
              <br />
              <span className="u-textAccent">
                抽選<strong>3</strong>名様
              </span>
              にプレゼント!!
            </p>
          </div>
          <footer>
            {isApply && !isTimeOver ? (
              <>
                <ApplicationKeyword />
                <a className={style.gifCTA} href={process.env.REACT_APP_APPLY_URL}>
                  <img src={txt_apply} alt="応募する" />
                </a>
              </>
            ) : undefined}

            {isTimeOver === false ? (
              <p className={style.giftPeriod}>
                応募期間：
                <ApplicationPeriod />
              </p>
            ) : (
              <p className={style.giftPeriod}>
                <strong>応募は締め切りました。</strong>
                次回もお楽しみに！
              </p>
            )}
          </footer>
        </article>

        {isTimeOver === false ? (
          <article className={[style.article, style.challenge].join(' ')}>
            <header>
              <small>
                <img src={hd_challengesub} alt="放送中に出題！" />
              </small>
              <h2>
                <img src={hd_challenge} alt="正解すればプレゼントGETのチャンス!" />
              </h2>
            </header>
            <ul className={style.list}>
              {questionList.map((item, i) => {
                // @ts-ignore
                const { isCorrect, release, quizId } = item;
                return i < 2 ? (
                  <li
                    className={style.question}
                    key={i}
                    data-correct={
                      // @ts-ignore
                      item.isCorrect === true
                        ? 'true'
                        : // @ts-ignore
                        item.isCorrect === false
                        ? 'false'
                        : undefined
                    }
                  >
                    <Bounce onClick={() => judge({ quizId, isCorrect, release })}>
                      <span
                        className={style.status}
                        data-release={`${releaseText(item.release)}ごろ出題`}
                        data-correct="結果をみる"
                      ></span>
                    </Bounce>
                  </li>
                ) : undefined;
              })}
            </ul>
          </article>
        ) : undefined}

        <article className={[style.article, style.warmup].join(' ')}>
          <Character
            type={0}
            wrapClass={style.charaBlueFooterPosition}
            imgClass={style.charaBlueFooter}
          />
          <header>
            <small>
              <img src={hd_warmupsub} alt="いつでも遊べる!" />
            </small>
            <h2>
              <img src={hd_warmup} alt="練習してみよう" />
            </h2>
            <p>放送日に新しく追加されるよ！</p>
          </header>
          <Bounce
            className={style.archive}
            onClick={() => {
              history.push(`/archive/0/1`);
            }}
          >
            <img src={hd_warmup_link} alt="問題一覧へ" />
          </Bounce>
          <footer>
            <Character
              type={2}
              wrapClass={style.charaRedFooterPosition}
              imgClass={style.charaRedFooter}
            />
          </footer>
        </article>
      </div>
    </div>
  );
};
