import { FC, useState, useMemo } from 'react';

// redux
import { useSelector } from 'react-redux';
import { RootState } from '../store';

// animation
import { useSpring, animated } from '@react-spring/web';

// assets
import style from '../assets/style/components/note.module.scss';

// function component
type Props = {
  storage?: string;
  color: string;
  text: string;
  index: number;
};

export const Note: FC<Props> = (props: Props) => {
  const { currentPlay } = useSelector((state: RootState) => state.quiz);

  const [isCurrent, setCurrent] = useState<boolean>(false);
  // const [myIndex, setIndex] = useState<number>(answer[props.index]);

  const { spring } = useSpring({
    loop: isCurrent,
    from: { spring: 0 },
    to: { spring: 1 },
    config: { duration: 500 },
  });

  useMemo(() => {
    // aria-currentの値を更新
    setCurrent(currentPlay === props.index);

    // アニメーションの再生を終了
    if (currentPlay !== props.index) spring.stop();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPlay]);

  return (
    <animated.span
      style={{
        transformOrigin: 'bottom center',
        y: spring.to({
          range: [0, 0.4, 0.66, 1],
          output: [0, -10, 0, 0],
        }),
        scaleX: spring.to({
          range: [0, 0.4, 0.7, 0.85, 1],
          output: [1, 0.95, 1, 1.05, 1],
        }),
        scaleY: spring.to({
          range: [0, 0.66, 0.8, 1],
          output: [1, 1, 0.98, 1],
        }),
      }}
      aria-current={isCurrent ? 'true' : undefined}
      data-label={props.text.toLocaleLowerCase()}
      className={[style.note, style[`note-${props.color}`]].join(' ')}
    ></animated.span>
  );
};
