import { FC } from 'react';

// assets
import style from '../assets/style/components/guide.module.scss';
import title_guide from '../assets/image/guide/title_guide.svg';
import visual1 from '../assets/image/guide/visual1.png';
import visual2 from '../assets/image/guide/visual2.png';
import visual3 from '../assets/image/guide/visual3.png';
import visual4 from '../assets/image/guide/visual4.png';
import visual5 from '../assets/image/guide/visual5.png';
import order1 from '../assets/image/guide/order1.svg';
import order2 from '../assets/image/guide/order2.svg';
import order3 from '../assets/image/guide/order3.svg';
import order4 from '../assets/image/guide/order4.svg';
import order5 from '../assets/image/guide/order5.svg';

export const Guide: FC = () => {
  return (
    <div className={style.guide}>
      <img className={style.title} src={title_guide} alt="遊び方" />
      <div className={style.content_text}>
        <p className={style.intro}>
          シャッフルされてバラバラになった音を
          <br />
          正しいメロディに並べ替えよう！
        </p>
      </div>
      <ul className={style.list}>
        <li className={style.item}>
          <h2 className={style.title_process}>
            <img className={style.order} src={order1} alt="1" />
            正しいメロディを確認！
          </h2>
          <img className={style.visual} src={visual1} alt="" />
          <div className={style.content_text}>
            <p className={style.sub_text}>
              ※音が出ない場合、ご利用端末の音量やサイレント設定を見直してみてください。
            </p>
          </div>
        </li>
        <li className={style.item}>
          <h2 className={style.title_process}>
            <img className={style.order} src={order2} alt="2" />
            バラバラになった音を聴く！
          </h2>
          <img className={style.visual} src={visual2} alt="" />
        </li>
        <li className={style.item}>
          <h2 className={style.title_process}>
            <img className={style.order} src={order3} alt="3" />
            100秒以内で
            <br />
            正しい順番に並べ替える！
          </h2>
          <img className={style.visual} src={visual3} alt="" />
          <div className={style.content_text}>
            <p className={style.text}>
              入れ替えたい音符に重ねて、指を離すと並び替えることができるよ。
            </p>
          </div>
        </li>
        <li className={style.item}>
          <h2 className={style.title_process}>
            <img className={style.order} src={order4} alt="4" />
            課題曲をききたいときは…
          </h2>
          <img className={style.visual} src={visual4} alt="" />
          <div className={style.content_text}>
            <p className={style.text}>
              正しいメロディはここから確認できるよ。（聴いてる間、タイマーはカウントされないよ）
            </p>
          </div>
        </li>
        <li className={style.item}>
          <h2 className={style.title_process}>
            <img className={style.order} src={order5} alt="5" />
            最後に完成ボタンをタップ！
          </h2>
          <img className={style.visual} src={visual5} alt="" />
        </li>
      </ul>
      <p className={style.caution}>※Wi-Fi環境下でのご利用を推奨いたします。</p>
    </div>
  );
};
