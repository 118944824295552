import React, { FC } from 'react';

// router
import { useHistory } from 'react-router-dom';

// assets
import style from '../assets/style/components/filter.module.scss';
import level_01 from '../assets/image/archive/level_star_01_inactive.svg';
import level_02 from '../assets/image/archive/level_star_02_inactive.svg';
import level_03 from '../assets/image/archive/level_star_03.svg';

// function component
type Props = {
  current: number;
};

export const Filter: FC<Props> = (props: Props) => {
  const history = useHistory();
  const { current } = props;
  const options = ['すべて', level_01, level_02, level_03];
  const alt = ['レベル1', 'レベル2', 'レベル3'];

  return (
    <div className={style.filter}>
      {options.map((item, i) => (
        <label className={style.item} key={i}>
          <input
            type="radio"
            value={i}
            onChange={e => {
              const newCurrent = e.target.value;
              history.push(`/archive/${newCurrent}/1`);
            }}
            checked={current === i}
          />
          <div className={style.button}>
            {item === options[0] ? <span>{item}</span> : <img src={options[i]} alt={alt[i - 1]} />}
          </div>
        </label>
      ))}
    </div>
  );
};
