import { createSlice } from '@reduxjs/toolkit';

type State = {
  dialogCondition: string;
  isOpen: boolean;
  content: HTMLElement | null;
  class: string[];
  callback: ((isOpen: boolean) => void) | null;
};

const initialState: State = {
  dialogCondition: '',
  isOpen: false,
  content: null,
  class: [],
  callback: null,
};

const dialog = createSlice({
  name: 'dialog',

  initialState,

  reducers: {
    updateCondition: (state, action) => {
      state.dialogCondition = action.payload;
    },
    updateOpen: (state, action) => {
      state.isOpen = action.payload;
      if (action.payload === true) {
        // open
        document.body.classList.add('isFixedScroll');
      } else {
        // close
        document.body.classList.remove('isFixedScroll');
        state.dialogCondition = '';
        state.class = [];
      }
    },
    updateContent: (state, action) => {
      state.content = action.payload;
    },
    updateClass: (state, action) => {
      state.class = [...action.payload];
    },
    setCallback: (state, action) => {
      if (action.payload) state.callback = action.payload;
    },
  },
});

// Action Creators
// eslint-disable-next-line prettier/prettier
export const { updateCondition, updateOpen, updateContent, updateClass, setCallback } =
  dialog.actions;

// Reducer
export default dialog.reducer;
