import { FC } from 'react';

// assets
import style from '../assets/style/components/character.module.scss';
import blue from '../assets/image/character_blue.png';
import red_01 from '../assets/image/character_red_01.png';
import red_02 from '../assets/image/character_red_02.png';

// function component
type Props = {
  type: number;
  wrapClass: string;
  imgClass: string;
};

export const Character: FC<Props> = (props: Props) => {
  const types = [blue, red_01, red_02];
  return (
    <div className={`${style.wrap} ${props.wrapClass}`}>
      <img className={props.imgClass} src={types[props.type]} alt="" />
    </div>
  );
};
